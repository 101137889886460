import React, { useEffect, useState, Fragment } from "react";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Formik, Field, FieldArray, getIn } from "formik";
import moment from 'moment'
import Page from "src/components/Page";
import FormHelperText from "@mui/material/FormHelperText";
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Grid,
  OutlinedInput,
  Typography,
  FormGroup,
  Autocomplete,Switch,
  FormControl,Radio,RadioGroup,FormControlLabel,FormLabel,Checkbox
} from "@mui/material";
import compareJSON from "src/components/comparision";
import CloseIcon from '@mui/icons-material/Close';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AlertModal from 'src/components/AlertModal/AlertModal'
import Button from "@mui/material/Button";
import NormalCard from "src/components/Cards/NormalCard";
import { securityService } from "src/_services/security";
import { getLookUpDataService } from "src/_services/lookUp";
import SnackBar from "src/components/SnackBar/SnackBar";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ErrorFocus from 'src/components/ErrorFocus/ErrorFocus'
import loadingSpinner from 'src/images/loading-spinner.gif'
import ReactSpinner from 'src/components/ReactSpinner/ReactSpinner';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
const PREFIX = 'SecurityCreate';

const classes = {
  root: `${PREFIX}-root`,
  addressBlock: `${PREFIX}-addressBlock`,
  gridPadding: `${PREFIX}-gridPadding`,
  accountBlock: `${PREFIX}-accountBlock`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  accountTitle: `${PREFIX}-accountTitle`,
  addressBlocktitle: `${PREFIX}-addressBlocktitle`,
  removeLink: `${PREFIX}-removeLink`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.addressBlock}`]: {
    padding: "1rem",
    color: "#314B8F",
  },

  [`& .${classes.gridPadding}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.accountBlock}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.accountTitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },

  [`& .${classes.addressBlocktitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  [`& .${classes.removeLink}`]: {
    cursor: 'pointer'
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "8px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  }
}));

export default function SecurityDataCreate() {

  const navigate = useNavigate();
  let query = useQuery();
  let location = useLocation();
  var today = new Date();



  const colorArray =[`#FFE08F`,"#5565B8", "#9BE999", "#E76161", "#AAA4EE"]
  // const emptyInvestorReport = {
  //   investorName:"",
  //   investorPercentage:"",
  //   investorcolor:  `#FFE08F`
  // }
  

  const [sectorLookUpData, setSectorLookUpData] = useState([]);
  const [countryLookUpData, setCountryLookUpData] = useState([]);
  const [stateLookUpData, setStateLookUpData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [mode, setMode] = useState(null);
  const [userID, setUserID] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();
  const [securityId, setSecurityId] = useState("")
  const [isSecurityLogo, setIsSecurityLogo] = useState("")
  // const [feeType, setFeeType] = React.useState([]);
  // const [overviewFile, setOverviewFile] = useState("")
  // const [fullReportFile, setFullReportFile] = useState("")
  const [deleteFileType, setDeleteFileType] = useState("")
  const [symbolsArr] =React.useState(["e", "E", "+", "-"]);
  const [securityTagData, setSecurityTagData] = useState([])
  const [fromStatus, setFromStatus] = useState(null)
  const [securityIndex, setSecurityIndex] = useState(0)

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [securityBasicData, setSecurityBasicData] = useState({
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    states: {
      "id": "",
      "code": "",
      "description": "",
      "related_country_id": ""
  },
    country: {
      "id": "",
      "code": "",
      "description": "",
      "country_code": "",
      "time_zone": ""
  },
    postalCode: "",
    scripName: "",
    companyName: "",
    sector: null,
    tags:[],
    isin: "",
    pan: "",
    cin: "",
    gstin: "",
    ipoDate:"",
    email: "",
    officeNo: "",
    faceValue: "",
    companyLogo: null,
    overview_report: null,
    full_report: null,
    report_price: null,
    fee_type: "",
    payment: "",
    status:"1",
    marketCap:null,
    company_overview: "",
    reportDate:"",
    nsdl:true,
    cdsl:true,
    totalInvestment:"",
    investorReport:[],
    revenue:"",
    eps:"",
    pat:"",
    marketCapital:"",
    rofr:false,
    outstandingShares:"",
    bookValue:"",
    pbvRatio:"",
    ratio:"",
    tradingStatus:false,
    sortIndex:securityIndex
  });

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)



async function getLastSortIndex(){
 let res  = await getLookUpDataService.getLastSecurityIndex()
 setSecurityIndex(res.data.data + 1)
   // .then(res => {
  //   if (res) {
  //     if (res.status === 200) {
  //       if (res.data) {
  //         console.log(res.data);
  //         setSecurityIndex()
  //       }
  //     } else {
  //       if (res.status === 401 && res.data.name === "TokenExpiredError") {
  //           setOpenSessionExpiredModal(true)
  //       }
  //     }
  //   }
  // })
  // .catch((error) => console.log(error));
  
}
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);


  const getEditModeData = () => {
    if (query.get("mode") === "edit") {
      setSpinner(true)
      securityService.getSecurityByID(location.state.userID).then(res => {
        if (res) {
          if (res.status === 200) {

            setIsSecurityLogo(res.data[0].logo_url)
            // setOverviewFile(res.data[0].overview_report[0].overview_report_file_url)
            // setFullReportFile(res.data[0].full_report[0].full_report_file_url)
            let state, country, payType, isPayment, companyOverview
            const financialData = res.data[0].financialdata !== "[]" ? JSON.parse(res.data[0].financialdata)  : null
            if (res.data[0].company_overview === null) {
              companyOverview = ""
            } else {
              companyOverview = res.data[0].company_overview
            }


            if (res.data[0].country_code !== null) {
              state = { code: res.data[0].state_code, description: res.data[0].state }
            } else {
              state = ""
            }

            if (res.data[0].country_code !== null) {
              country = { code: res.data[0].country_code, description: res.data[0].country }
            } else {
              country = ""
            }

            if (res.data[0].full_report[0].fee_type === null) {
              payType = ""
              isPayment = ""
            } else {
              payType = { code: res.data[0].full_report[0].fee_type_code, label: res.data[0].full_report[0].fee_type }
              isPayment = res.data[0].full_report[0].fee_type_code
            }
            setFromStatus(res.data[0].status)
            const initValues = {
              addressLineOne: res.data[0].address1,
              addressLineTwo: res.data[0].address2,
              city: res.data[0].city,
              states: state,
              country: country,
              postalCode: res.data[0].zipcode,
              scripName: res.data[0].name,
              isin: res.data[0].isin,
              companyName: res.data[0].parent_company_name,
              sector: { code: res.data[0].sector_code, description: res.data[0].sector },
              pan: res.data[0].pan,
              cin: res.data[0].register_no,
              gstin: res.data[0].gstin,
              email: (res.data[0].email === null ? "" : res.data[0].email),
              officeNo: (res.data[0].office_no === null ? "" : res.data[0].office_no),
              faceValue: parseFloat(res.data[0].face_value),
              status:JSON.stringify(res.data[0].status),
              companyLogo: null,
              overview_report: null,
              full_report: null,
              report_price: (res.data[0].full_report[0].report_price === null ? "" : res.data[0].full_report[0].report_price),
              fee_type: payType,
              payment: isPayment,
              company_overview: companyOverview,
              tags:res.data[0].related_security_tag ? res.data[0].related_security_tag : [],
              investorReport:res.data[0].keyinvestordata!=null ?  res.data[0].keyinvestordata !== "[]" ? JSON.parse(res.data[0].keyinvestordata) : [] : [],
              revenue:  financialData!= null ?  financialData[0] ? financialData[0].financialValue : "" : "",
              eps: financialData!= null ?  financialData[1] ? financialData[1].financialValue : "": "",
              pat:  financialData!= null ? financialData[2] ? financialData[2].financialValue  : "": "",
              ratio: financialData!= null ?  financialData[3] ? financialData[3].financialValue : "": "",
              reportDate: moment(res.data[0].financiallastupdatedate).format("YYYY-MM-DD"),
              totalInvestment:res.data[0].totalinvestment,
              hidingReason:res.data[0].hiding_reason,
              ipoDate:moment(res.data[0].ipo_date).format("YYYY-MM-DD"),
              nsdl: res.data[0].available_nsdl ? res.data[0].available_nsdl : false,
              cdsl: res.data[0].available_cdsl ? res.data[0].available_nsdl : false,
              sortIndex:res.data[0].sort_index,
              tradingStatus: res.data[0].hide_trading_status,
              marketCapital:res.data[0].market_capitalization_value,
              rofr:res.data[0].rofr,
              outstandingShares:res.data[0].outstanding_shares_count,
              bookValue:res.data[0].book_value,
              pbvRatio:res.data[0].pbv_ratio,
              marketCap:res.data[0].related_market_capital_id===null ? res.data[0].related_market_capital_id : JSON.stringify(res.data[0].related_market_capital_id)
            }

            setSecurityBasicData(initValues)
            setSpinner(false)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      }).catch((error) => console.log(error));
    }
  }

  const getSecurityTag = () => {
    setLoading(true)
    securityService.getSecurityTags()
        .then(res => {
            if (res) {
                if (res.status === 200) {
                    setSecurityTagData(res.data.data)
                    setLoading(false)
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                        setLoading(false)
                    }
                }

            }
        }).catch(error => console.log(error))
}

  useEffect(() => {
    getEditModeData();
    getSecurityTag()
  }, [userID]);

  useEffect(() => {
    setMode(query.get("mode") || null);

    if (query.get("mode") === "edit") {
      setUserID(location.state.userID || null);
    }
  }, []);

  useEffect(() => {
    getLookUpDataService.getLookUpData("sector")
      .then(res => {
        if (res) {
          if (res.status === 200) {
            if (res.data) {
              setSectorLookUpData(res.data[0].sector)
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      }).catch(error => console.log(error))


    getLookUpDataService.getLookUpData("state ")
      .then(res => {
        if (res) {

          if (res.status === 200) {
            if (res.data) {
              setStateLookUpData(res.data[0].state)
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      }).catch(error => console.log(error))

   

    getLookUpDataService.getLookUpData("country")
      .then(res => {
        if (res) {
          if (res.status === 200) {
            if (res.data) {
              setCountryLookUpData(res.data[0].country)
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((error) => console.log(error));
      getLastSortIndex()
      // getLookUpDataService.getLastSecurityIndex()
      // .then(res => {
      //   if (res) {
      //     if (res.status === 200) {
      //       if (res.data) {
      //         console.log(res.data);
      //         setSecurityIndex(res.data.data)
      //       }
      //     } else {
      //       if (res.status === 401 && res.data.name === "TokenExpiredError") {
      //           setOpenSessionExpiredModal(true)
      //       }
      //     }
      //   }
      // })
      // .catch((error) => console.log(error));
      

      
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };




  const handleOnSubmit = (values) => {
    const userTags = values.tags.map((item) => item.id)
    const financialValue =[{
      financialName:"Revenue",
      financialValue:values.revenue
    },
    {
      financialName:"EPS",
      financialValue:values.eps
    },
    {
      financialName:"PAT",
      financialValue:values.pat
    },
    {
      financialName:"P/E Ratio",
      financialValue:values.ratio
    }]
    const result = compareJSON(values, securityBasicData);
    const formData = new FormData();
    formData.append('name', values.scripName) 
    formData.append('parent_company_name', values.companyName)
    formData.append('isin', values.isin)
    formData.append('sector', values.sector.code)
    formData.append('pan', values.pan)
    formData.append('register_no', values.cin)
    formData.append('gstin', values.gstin)
    formData.append('email', values.email)
    formData.append('office_no', values.officeNo)
    formData.append('security_logo', values.companyLogo)
    formData.append('face_value', values.faceValue)
    formData.append('related_security_tag_id', JSON.stringify(userTags))
    formData.append('overview_report', values.overview_report)
    formData.append('full_report', values.full_report)
    formData.append('report_price', values.report_price)
    formData.append('company_overview', values.company_overview)
    formData.append('status', values.status)
    formData.append('financiallastupdatedate', values.reportDate)
    formData.append('totalinvestment', values.totalInvestment)
    formData.append('financialdata', JSON.stringify(financialValue))
    formData.append('keyinvestordata', JSON.stringify(values.investorReport))
    formData.append('related_market_capital_id', values.marketCap)
    formData.append('available_cdsl',values.cdsl )
    formData.append('available_nsdl', values.nsdl)
    formData.append('sort_index',securityIndex)
    formData.append('hide_trading_status',values.tradingStatus)
    formData.append('outstanding_shares_count',values.outstandingShares ? values.outstandingShares: null)
    formData.append('book_value',values.bookValue ? values.bookValue: null)
    formData.append('market_capitalization_value',values.marketCapital ? values.marketCapital: null)
    formData.append('pbv_ratio',values.pbvRatio ? values.pbvRatio: null)
    formData.append('rofr',values.rofr ?values.rofr : false)
    formData.append('hiding_reason',values.hidingReason)
    formData.append('ipo_date',values.ipoDate)
    formData.append('updatedValues', mode ==='edit' && JSON.stringify(result))


    if (values.fee_type !== undefined) {
      formData.append('fee_type', values.fee_type)
    }

    if (values.addressLineOne !== "" && mode === "create") {
      let address = {
        address1: values.addressLineOne || null,
        address2: values.addressLineTwo || null,
        city: values.city || null,
        state: values.states.code || null,
        country: values.country.code || null,
        zipcode: values.postalCode || null,
      };
      formData.append('address', JSON.stringify(address))
    } else if (values.addressLineOne !== null && mode === "edit") {
      const address = {
        address1: values.addressLineOne || "",
        address2: values.addressLineTwo,
        city: values.city,
        state: values.states && values.states.code,
        country: values.country && values.country.code,
        zipcode: values.postalCode,
      };
      formData.append('address', JSON.stringify(address))
      formData.append("from_status", fromStatus)
    } else {
      formData.append("from_status", fromStatus)
    }

    if (mode === "create") {
      securityService.postSecurity(formData).then((res) => {
        if (res) {

          if (res.status === 400) {
            if (res.data === "SECTOR_NOT_FOUND") {
              setLoading(false);
              setSeverity("error")
              setSnackbarTitle("Sector Not Found")
              setOpen(true);
            }
            else if (res.data === "ISIN_USED") {
              setLoading(false);
              setSeverity("error")
              setSnackbarTitle(" ISIN Already Used ")
              setOpen(true);
            }
            else if (res.data === "PAN_ALREADY_USED") {
              setLoading(false);
              setSeverity("error")
              setSnackbarTitle("Pan Already Used ")
              setOpen(true);
            }else{
              setLoading(false);
              setSeverity("error")
              setSnackbarTitle("Something went wrong")
              setOpen(true);
            }
          }

          else if (res.status === 201) {
            setLoading(false);
            setSeverity("success")
            setSnackbarTitle("Created Successfully")
            setOpen(true);
            setTimeout(() => navigate(`/app/security-details/${res.data.id}`, { replace: true }), 1000)
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true)
            }
          }
        }
      }).catch(error => {
            setLoading(false);
            setSeverity("error")
            setSnackbarTitle("Something went wrong")
            setOpen(true);
      })
    }
    else if (mode === "edit") {
      securityService.putSecurity(userID, formData)
        .then(res => {
          if (res) {

            if (res.status === 400) {
              if (res.data === "SECTOR_NOT_FOUND") {
                setLoading(false);
                setSeverity("error")
                setSnackbarTitle("Sector Not Found")
                setOpen(true);
              }
              else if (res.data === "ISIN_USED") {
                setLoading(false);
                setSeverity("error")
                setSnackbarTitle(" ISIN Already Used ")
                setOpen(true);
              }
              else if (res.data === "PAN_ALREADY_USED") {
                setLoading(false);
                setSeverity("error")
                setSnackbarTitle("Pan Already Used ")
                setOpen(true);
              }else{
                setLoading(false);
                setSeverity("error")
                setSnackbarTitle("Something went wrong")
                setOpen(true);
              }
            }
            else if (res.status === 200) {
              setLoading(false);
              setSeverity("success")
              setSnackbarTitle("Updated Successfully")
              setOpen(true);
              setTimeout(() => navigate(`/app/security-details/${userID}`, { replace: true }), 1000)
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setLoading(false);
                setOpenSessionExpiredModal(true)
              }
            }
          } else if (res.status === 200) {
            setLoading(false);
            setSeverity("success");
            setSnackbarTitle("Updated Successfully");
            setOpen(true);
            setTimeout(
              () =>
                navigate(`/app/security-details/${userID}`, {
                  replace: true,
                }),
              1000
            );
          }
        })
        .catch((error) => {
          setLoading(false);
            setSeverity("error")
            setSnackbarTitle("Something went wrong")
            setOpen(true);
        });
    } else {
    }
  };





  const removeOverViewFile = () => {
    securityService.deleteSecurityOverViewFile(securityId).then((res) => {
      if (res) {
        if (res.status === 200) {
          getEditModeData();
          setDeleteOpen(false)
        }
      }
    });
  };

  const removeFullReportFile = () => {
    securityService.deleteSecurityFullReportFile(securityId).then((res) => {
      if (res) {
        if (res.status === 200) {
          getEditModeData();
          setDeleteOpen(false)
        }
      }
    });
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
  ];
  const pdf_format = ['application/pdf']

  const handleDeleteSecurityLogo = () => {
    securityService.deleteSecurityLogo(securityId).then((res) => {
      if (res) {
        if (res.status === 200) {
          getEditModeData();
          setDeleteOpen(false)
        }
      }
    });
  }

  const getWordCount =((value)=>{
    return value?.split(/\s+/).length;
  })

  return (
    <Root>
      <Page title="Unlistedkart | Create Security">
        <Formik
          enableReinitialize={true}
          initialValues={securityBasicData}
          validationSchema={Yup.object().shape({
            companyName: Yup.string()
              .nullable()
              .required("Company Name is required"),
              sortIndex:Yup.string()
              .nullable(),
              // .matches("[0-9]","Sort Index value Should only be Number")
              // .required("SortIndex is required"),
            scripName: Yup.string()
              .nullable()
              .required("Scrip Name is required"),
            marketCap: Yup.string()
              .nullable()
              .required("Market Capital is required"),
            isin: Yup.string()
              .nullable()
              .required("ISIN is required")
              .matches("([A-Za-z]{2})[0-9A-Za-z]{10}", "Invalid"),
            faceValue: Yup.number()
              .nullable()
              .required("Face Value is required")
              .test(
                'Is positive?', 
                'Face value must be greater than 0', 
                (value) => value > 0
              ),
              // .max(6, "max is 6"),
            postalCode: Yup.string()
              .nullable()
              .max(10, "Invalid Pin Code"),
            sector: Yup.string()
              .nullable()
              .required("Sector is required"),
              reportDate:Yup.string().required("Required").test("DOB",
              `Date must be equal to or less than today's date ${moment(today).format("DD-MM-YYYY")}`,
              value => {
                // setStartDate(value)
                return value <= moment(today).format("YYYY-MM-DD");
            }
             ),
             ipoDate:Yup.string().when("hidingReason", {
              is: (data) => data === "Listed Public",
              then:Yup.string().required("Required").test("DOB",
              `Date must be equal to or less than today's date ${moment(today).format("DD-MM-YYYY")}`,
              value => {
                return value <= moment(today).format("YYYY-MM-DD");
              }),
              otherwise:Yup.string().nullable()
            }
             ),
            company_overview:Yup.string().nullable().test("len",
            'Must be maximum of 200 words',
            value =>{
              if( getWordCount(value) <= 200 || getWordCount(value) === undefined){
                return true
              }
            }  ),
            cin: Yup.string()
              .nullable()
              .max(21, "max is 21"),
            // .matches(
            //   "([A-Z]{1})[0-9]{5}([A-Z]{2})[0-9]{4}([A-Z]{3})[0-9]{6}",
            //   "Invalid"
            // ),
            status: Yup.string(),
            tags: Yup.string(),
            totalInvestment: Yup.string()
              .nullable(),
              // .required("Total investment is required"),
            investorReport: Yup.array(
              Yup.object({
                investorName: Yup.string()
                  .required('Investor name required'),
                  investorPercentage: Yup.number()
                  .required('Investor percentage required'),
              })
            ),
            email: Yup.string()
              .nullable()
              .email("Enter a valid email"),

            pan: Yup.string()
              .max(10, "max is 10 character")
              .nullable()
              .matches("[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}", "PAN is invalid"),
            officeNo: Yup.string()
              .nullable()
              .max(20, "max is 20"),
            gstin: Yup.string()
              .nullable()
              .matches(
                "[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}",
                "GSTIN is invalid"
              ),
            companyLogo: Yup.mixed()
              // .required("Required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
              )
              // .test(
              //   'fileAspectRatio',
              //   'Please recheck the image resolution',
              //   value => value && value.aspectRatio === 16.8
              // )
              // .test(
              //   'fileAspectRatio',
              //   'Please recheck the image resolution',
              //   value => value && value.aspectRatio === 4.16
              // )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value === null || (value && value.size < 10242880)
              ),
            overview_report: Yup.mixed()
              // .required("Required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value === null || (value && pdf_format.includes(value.type))
              )
              // .test(
              //   'fileAspectRatio',
              //   'Please recheck the image resolution',
              //   value => value && value.aspectRatio === 16.8
              // )
              .test(
                "fileSize",
                "Maximum file size is 25MB",
                (value) => value === null || (value && value.size < 26214400)
              ),
            full_report: Yup.mixed()
              // .required("Required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value === null || (value && pdf_format.includes(value.type))
              )
              // .test(
              //   'fileAspectRatio',
              //   'Please recheck the image resolution',
              //   value => value && value.aspectRatio === 16.8
              // )
              .test(
                "fileSize",
                "Maximum file size is 25MB",
                (value) => value === null || (value && value.size < 26214400)
              ),

            fee_type: Yup.string().when("full_report", {
              is: (full_report) => full_report !== null,
              then: Yup.string().required("Required"),
              otherwise: Yup.string(),
            }),
            hidingReason: Yup.string().when("tradingStatus", {
              is: true,
              then: Yup.string().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            report_price: Yup.number().positive().nullable().when("payment", {
              is: "PAID",
              then: Yup.number()
                .positive("The price must be a positive number").nullable().required("Required"),
              otherwise: Yup.number(),
            }),
            // revenue: Yup.string()
            //   .nullable()
            //   .required("Revenue is required"),
            // eps: Yup.string()
            //   .nullable()
            //   .required("EPS is required"),
            // pat: Yup.string()
            //   .nullable()
            //   .required("PAT is required"),
            // ratio: Yup.string()
            //   .nullable()
            //   .required("P/E Ratio is required"),
          })}
          onSubmit={(values) => {
            setLoading(true);
            delete values.payment
            if (values.fee_type !== "") {
              values.fee_type = values.fee_type.code
            } else {
              delete values.fee_type
            }
            if (values.report_price !== "" && values.report_price !== null) {
              values.report_price = parseFloat(values.report_price.toFixed(2))
            } else {
              values.report_price = null
            }
            handleOnSubmit(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ padding: "1rem" }}
              autoComplete="off"
              noValidate
            >
              <NormalCard title="Create Scrip">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div style={{ padding: "1rem" }}>
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ marginTop: "-10px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        variant="standard"
                        label="Company Name*"
                        id="companyName"
                        name="companyName"
                        autoComplete="off"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(
                          touched.companyName && errors.companyName
                        )}
                        helperText={touched.companyName && errors.companyName}
                        value={values.companyName}
                      />
                      <ErrorFocus />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Scrip Name*"
                        id="scripName"
                        name="scripName"
                        autoComplete="off"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.scripName && errors.scripName)}
                        helperText={touched.scripName && errors.scripName}
                        value={values.scripName}
                      />
                      <ErrorFocus />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label=" ISIN*"
                        name="isin"
                        id="isin"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        inputProps={{ maxLength: 12 }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.isin && errors.isin)}
                        helperText={touched.isin && errors.isin}
                        value={values.isin}
                      />
                      <ErrorFocus />
                    </Grid>
                    {isSecurityLogo && <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridPadding} style={{textAlign:"center"}}>
                      <img src={isSecurityLogo} width="80px" alt="securityLogo" />
                      <Typography className={classes.removeLink} color="primary" variant="body1"
                        onClick={() => {
                          setSecurityId(location.state.userID);
                          setDeleteOpen(true)
                          setDeleteFileType("logoFile")
                        }
                        }
                      >Change/Remove</Typography>
                    </Grid>}
                    {(mode === "create" || isSecurityLogo === null) &&
                      <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridPadding}>
                        <div>
                          <Typography className={classes.label}>
                            Company Logo
                          </Typography>
                          <OutlinedInput
                            accept="image/*"
                            // className={classes.input}
                            id="icon-button-file"
                            name="companyLogo"
                            fullWidth={true}
                            error={
                              touched.companyLogo && Boolean(errors.companyLogo)
                            }
                            // helperText={
                            //   touched.companyLogo ? errors.companyLogo : ""
                            // }
                            onChange={(e) => {
                              let file = e.currentTarget.files[0]
                              setFieldValue("companyLogo", file);
                              const url =file &&  URL.createObjectURL(file);
                              setImageUrl(url);
                            }}
                            type="file"
                          />
                          <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }} >
                            {touched.companyLogo && errors.companyLogo}
                          </FormHelperText>
                        </div>
                        <ErrorFocus />
                      </Grid>
                    }
                  </Grid>

                  <Grid container spacing={5} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <Autocomplete
                        id="sectore-id"
                        options={sectorLookUpData}
                        isOptionEqualToValue={(option, value) => value === "" ? null : option}
                        getOptionLabel={(option) => option.description || ' '}
                        value={values.sector}
                        onChange={(e, value) => {
                          setFieldValue("sector", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Sector*"
                            name="sector"
                            // onChange={handleChange}
                            error={Boolean(touched.sector && errors.sector)}
                            helperText={touched.sector && errors.sector}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label="Sort Index*"
                        id="sortIndex"
                        name="sortIndex"
                        autoComplete="off"
                        fullWidth
                        onBlur={handleBlur}
                        onChange={(e)=>{
                          setSecurityIndex(e.target.value)
                        }}
                        error={Boolean(
                          touched.sortIndex && errors.sortIndex
                        )}
                        helperText={touched.sortIndex && errors.sortIndex}
                        value={securityIndex}
                      />
                      <ErrorFocus />
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      </Grid>
                      <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      </Grid>
                
                    {imageUrl && <Grid item xs={12} sm={6} md={3} lg={3} className={classes.gridPadding} style={{textAlign:"center"}}>
                      <img src={imageUrl} width="80px" alt="securityLogo" />
                    </Grid>}
                  </Grid>

                  <Grid container spacing={5} alignItems="center">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className={classes.gridPadding}
                    >
                       <FormControl component="fieldset">
                       <FormLabel component="legend">Status</FormLabel>
                       <RadioGroup row aria-label="gender" name="status"
                        value={values.status}
                        id="status" 
                       onBlur={handleBlur}
                       onChange={handleChange}
                      //  error={Boolean(touched.status && errors.status)}
                      //  helperText={touched.status && errors.status}
                       >
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="Available" />
                        <FormControlLabel value="2" control={<Radio  color="primary" />} label="Out of stock" />
                        <FormControlLabel value="3" control={<Radio  color="primary" />} label="Coming soon" />
                      </RadioGroup>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                       <FormControl component="fieldset" error={Boolean(touched.marketCap && errors.marketCap)}>
                       <FormLabel component="legend">Market Capital</FormLabel>
                       <RadioGroup row aria-label="gender" name="marketCap"
                        value={values.marketCap}
                        id="marketCap" 
                        onBlur={handleBlur}
                        onChange={handleChange}>
                        {/* <FormControlLabel value="1" control={<Radio color="primary" />} label="Micro" /> */}
                        <FormControlLabel value="1" control={<Radio  color="primary" />} label="Small" />
                        <FormControlLabel value="2" control={<Radio  color="primary" />} label="Middle" />
                        <FormControlLabel value="3" control={<Radio  color="primary" />} label="Large" />
                      </RadioGroup>
                      <FormHelperText>{touched.marketCap && errors.marketCap}</FormHelperText>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={2}
                      lg={2}
                      className={classes.gridPadding}
                    >
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                          <FormLabel component="legend">Depository Status</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.nsdl}
                                name="nsdl"
                                id="nsdl"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="primary"
                              />
                              }
                              label="NSDL"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.cdsl}
                                name="cdsl"
                                id="cdsl"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="primary"
                              />
                              }
                              label="CDSL"
                            />
                          </FormGroup>
                        </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5} alignItems="center">
                  <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                        <FormControl  component="fieldset" variant="standard">
                          <FormLabel component="legend">Trading Status</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.tradingStatus}
                                name="tradingStatus"
                                id="tradingStatus"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={(e)=>{
                                  if(e.target.checked){
                                    setFieldValue('tradingStatus',e.target.checked)
                                     setFieldValue('hidingReason',"Listed Public");
                                  }else{
                                    setFieldValue('tradingStatus',e.target.checked)
                                    setFieldValue('hidingReason','');
                                  }
                                }}
                                color="primary"
                              />
                              }
                              label={<Typography>Public</Typography>}
                            />
                            </FormGroup>
                        </FormControl>
                  </Grid>
                   {values.tradingStatus ?    
                   <> 
                   <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                        <Autocomplete    
                          value={values.hidingReason}
                          onChange={(event, newValue) => {
                            setFieldValue('hidingReason',newValue);
                            if(newValue !=='Listed Public'){
                              setFieldValue('ipoDate','');
                            }
                          }}
                          options={['Listed Public','Out of Stock','Others']}
                          freeSolo
                          onInputChange={(e)=>{
                            setFieldValue('hidingReason', e ?  e.target.value : values.hidingReason);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} 
                            variant="standard"
                            label={'Unavailability Reason*'}
                            error={Boolean(
                              touched.hidingReason && errors.hidingReason
                            )}
                            helperText={touched.hidingReason && errors.hidingReason}
                             />
                          )}
                        />
                      <ErrorFocus />
                    </Grid>
                    {values.hidingReason ==="Listed Public" && <Grid
                     item
                     xs={12}
                     sm={6}
                     md={3}
                     lg={3}
                     className={classes.gridPadding}
                   >
                      <TextField
                      fullWidth
                       id="ipDate"
                       label="IPO Date*"
                       type="date"
                       variant="standard"
                       error={Boolean(touched.ipoDate && errors.ipoDate)}
                       helperText={touched.ipoDate && errors.ipoDate}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       name="ipoDate"
                       value={values.ipoDate}
                       InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                       InputLabelProps={{
                           readOnly: true,
                           shrink: true
                       }}                                           
                   />
                   <ErrorFocus />
                     </Grid>}
                     </>
                    // </Grid>
                    : null}
                    </Grid>
                </div>
              </NormalCard>
              <NormalCard title="Other Details">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div style={{ padding: "1rem" }}>
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ marginTop: "-10px" }}
                  >
                      <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label="Office No"
                        id="officeNo"
                        name="officeNo"
                        variant="standard"
                        autoComplete="off"
                        value={values.officeNo}
                        fullWidth
                        inputProps={{ maxLength: 20 }}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^+0-9]/g, "");
                        }}
                      />
                      <ErrorFocus />
                    </Grid>
                      <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label="CIN or REG NO"
                        id="cin"
                        name="cin"
                        fullWidth
                        variant="standard"
                        autoComplete="off"
                        inputProps={{ maxLength: 21 }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.cin && errors.cin)}
                        helperText={touched.cin && errors.cin}
                        value={values.cin}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        autoComplete="off"
                        label="Email ID"
                        id="email"
                        name="email"
                        variant="standard"
                        fullWidth
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <ErrorFocus />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label="PAN"
                        id="pan"
                        name="pan"
                        variant="standard"
                        autoComplete="off"
                        value={values.pan}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.pan && errors.pan)}
                        fullWidth
                        helperText={touched.pan && errors.pan}

                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label="GSTIN"
                        id="gstin"
                        name="gstin"
                        variant="standard"
                        autoComplete="off"
                        value={values.gstin}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.gstin && errors.gstin)}
                        fullWidth
                        helperText={touched.gstin && errors.gstin}
                        inputProps={{ maxLength: 15 }}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              <NormalCard title="Scrip tags">
                <div style={{ padding: "1rem" }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      className={classes.gridPadding}
                    >
                         <Autocomplete
                         multiple
                        id="tags-id"
                        isOptionEqualToValue={(option, value) => value === "" ? null : option}
                        options={securityTagData.filter(function(data) {
                          return (
                            values.tags.filter(function(item) {
                              return item.tag_name === data.tag_name;
                            }).length === 0
                          );
                        })}
                        getOptionLabel={(option) =>   option.tag_name || ""}
                        value={values.tags}
                        onChange={(e, value) => {
                          setFieldValue("tags", value);
                        }}
                        renderOption={option => {
                              return (
                                  <Fragment>
                                     {/* <img src={option.tag_icon_url}  width={20} height={20} style={{marginRight:"8px"}}/>  */}
                                      {option.tag_name}
                                  </Fragment>
                              );
                          }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Tags"
                            name="tags"
                            // onChange={handleChange}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              <NormalCard title="Registered address of the company (optional)">
                {spinner && (
                  <div className={classes.reactSpinner}>
                    <ReactSpinner />
                  </div>
                )}
                <div style={{ padding: "1rem" }}>
                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ paddingTop: "1rem" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      // className={classes.gridPadding}
                    >
                      <TextField
                        id="addressLineOne"
                        label="Address Line 1 "
                        name="addressLineOne"
                        variant="standard"
                        autoComplete="off"
                        value={values.addressLineOne}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      // className={classes.gridPadding}
                    >
                      <TextField
                        id="addressLineTwo"
                        label="Address Line 2"
                        name="addressLineTwo"
                        variant="standard"
                        autoComplete="off"
                        fullWidth
                        value={values.addressLineTwo}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      // className={classes.gridPadding}
                    >
                      <TextField
                        id="city"
                        label="City"
                        name="city"
                        variant="standard"
                        autoComplete="off"
                        fullWidth={true}
                        value={values.city}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      // className={classes.gridPadding}
                    >
                      <Autocomplete
                        id="state-id"
                        options={stateLookUpData}
                        isOptionEqualToValue={(option, value) => value === "" ? null : option}
                        getOptionLabel={(option) => option.description || ''}
                        value={values.states}
                        onChange={(e, value) => {
                          setFieldValue("states", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="State"
                            name="states"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="postalCode"
                        name="postalCode"
                        label="Postal Code"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.postalCode && errors.postalCode)}
                        helperText={touched.postalCode && errors.postalCode}
                        value={values.postalCode}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      my={3}
                      // className={classes.gridPadding}
                    >
                      <Autocomplete
                        id="country-id"
                        options={countryLookUpData}
                        isOptionEqualToValue={(option, value) => value === "" ? null : option}
                        getOptionLabel={(option) => option.description || ''}
                        value={values.country}
                        onChange={(e, value) => {
                          setFieldValue("country", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Country"
                            name="country"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              <NormalCard title="Key Investors/ Stakeholders">
              <div style={{ padding: "1rem" }}>
              <FieldArray
              name="investorReport">
              {({ push, remove }) => (
                <div >
                  {values.investorReport.map((_, index) => {
                     const touchedInvestorName = getIn(touched, `investorReport.${index}.investorName`);
                     const errorInvestorName = getIn(errors, `investorReport.${index}.investorName`);
                     const touchedInvestorPercentage = getIn(touched, `investorReport.${index}.investorPercentage`);
                     const errorInvestorPercentage = getIn(errors, `investorReport.${index}.investorPercentage`);
                    return(
                      (
                        <div key={index}>
                          {/** both these conventions do the same */}
                          <Grid container spacing={5} alignItems="center" key={index}>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label="Investor Name*"
                           id="investor_key_name"
                           autoComplete="off"
                           fullWidth
                           variant="standard"
                           name={`investorReport.${index}.investorName`}
                           value={values.investorReport[index].investorName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean( touchedInvestorName && errorInvestorName)}
                            helperText={ touchedInvestorName && errorInvestorName}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label="Investment Percentage*"
                           variant="standard"
                           id="investorPercentage"
                           name={`investorReport.${index}.investorPercentage`}
                           value={values.investorReport[index].investorPercentage}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
    
                           onBlur={handleBlur}
                           error={Boolean(touchedInvestorPercentage && errorInvestorPercentage)}
                           helperText={touchedInvestorPercentage && errorInvestorPercentage}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={2}
                          lg={2}
                          className={classes.gridPadding}
                        >
                          <TextField
                           InputLabelProps={{
                            shrink: true,
                            }}
                            // InputProps={{ disableUnderline: true, }}
                            style={{marginTop:"8px"}}
                           label="Investor color*"
                           id="investorcolor"
                           variant="standard"
                           type="color"
                           name={`investorReport.${index}.investorcolor`}
                           value={values.investorReport[index].investorcolor}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
    
                           onBlur={handleBlur}
                          //  error={Boolean(touchedInvestorcolor && errorInvestorcolor)}
                          //  helperText={touchedInvestorcolor && errorInvestorcolor}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          className={classes.gridPadding}
                         
                        >
                        <CloseIcon   onClick={() => {remove(index)}} style={{marginTop:"1.5rem"}}  />
                          </Grid>
                          </Grid>
                            </div>
                          )
                    )
                  })}
                        <Button
                      variant="contained"
                      color="primary"
                      style={{margin:'2rem 0'}}
                      onClick={() => {
                          push({
                            investorName:"",
                            investorPercentage:"",
                            investorcolor: values.investorReport.length<5 ? colorArray[values.investorReport.length] : `#${Math.floor(Math.random()*16777215).toString(16)}`
                          })
                      }}
                   >
                    Add Investor Data
                   </Button>
                   <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="totalInvestment"
                        name="totalInvestment"
                        label="Total Investment"
                        variant="standard"
                        autoComplete="off"
                        fullWidth
                        error={Boolean(touched.totalInvestment && errors.totalInvestment)}
                        helperText={touched.totalInvestment && errors.totalInvestment}
                        value={values.totalInvestment}
                        onChange={handleChange}
                      />
                    </Grid>
                      </div>
                    )}
                  </FieldArray>
                      </div>
              </NormalCard>       
              <NormalCard title="Financial Data">
              <div style={{ padding: "1rem" }}>
                    <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="revenue"
                        name="revenue"
                        label="Revenue"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.revenue && errors.revenue)}
                        helperText={touched.revenue && errors.revenue}
                        value={values.revenue}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="eps"
                        name="eps"
                        label="EPS"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.eps && errors.eps)}
                        helperText={touched.eps && errors.eps}
                        value={values.eps}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="pat"
                        name="pat"
                        label="PAT"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.pat && errors.pat)}
                        helperText={touched.pat && errors.pat}
                        value={values.pat}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="ratio"
                        name="ratio"
                        label="P/E Ratio"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.ratio && errors.ratio)}
                        helperText={touched.ratio && errors.ratio}
                        value={values.ratio}
                        onChange={handleChange}
                        // inputProps={{ maxLength: 10 }}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="outstandingShares"
                        name="outstandingShares"
                        label="Outstanding Shares"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.outstandingShares && errors.outstandingShares)}
                        helperText={touched.outstandingShares && errors.outstandingShares}
                        value={values.outstandingShares}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="marketCapital"
                        name="marketCapital"
                        label="Market Capitalization"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.marketCapital && errors.marketCapital)}
                        helperText={touched.marketCapital && errors.marketCapital}
                        value={values.marketCapital}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="bookValue"
                        name="bookValue"
                        label="Book Value"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.bookValue && errors.bookValue)}
                        helperText={touched.bookValue && errors.bookValue}
                        value={values.bookValue}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        id="pbvRatio"
                        name="pbvRatio"
                        label="P/BV Ratio"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.pbvRatio && errors.pbvRatio)}
                        helperText={touched.pbvRatio && errors.pbvRatio}
                        value={values.pbvRatio}
                        onChange={handleChange}
                        type="number"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                      <TextField
                        label=" Face Value*"
                        id="faceValue"
                        name="faceValue"
                        autoComplete="off"
                        fullWidth
                        variant="standard"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.faceValue && errors.faceValue)}
                        helperText={touched.faceValue && errors.faceValue}
                        value={values.faceValue}
                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                        type="number"
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className={classes.gridPadding}
                    >
                       <TextField
                       fullWidth
                        id="endDate"
                        label="Report Date*"
                        type="date"
                        variant="standard"
                        error={Boolean(touched.reportDate && errors.reportDate)}
                        helperText={touched.reportDate && errors.reportDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="reportDate"
                        value={values.reportDate}
                        InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                        InputLabelProps={{
                            readOnly: true,
                            shrink: true
                        }}                                           
                    />
                    <ErrorFocus />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                    <FormControlLabel 
                     id="rofr"
                     name="rofr"
                     autoComplete="off"
                     fullWidth
                     variant="standard"
                     error={Boolean(touched.rofr && errors.rofr)}
                     helperText={touched.rofr && errors.rofr}
                     value={values.rofr}
                     onChange={handleChange}
                     control={<Switch checked={values.rofr} />} label="ROFR Require" />
                    </Grid>
                    </Grid>
                      </div>
              </NormalCard>
              <NormalCard title="Company Overview">
                <div style={{ padding: "1rem" }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={classes.gridPadding}
                    >
                      {/* <Typography className={classes.label}>
                        Company Overview
                      </Typography> */}
                      <Field
                        name="company_overview">
                        {({ field, form }) => {
                          return (
                            <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                config={{
                                  toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', 'insertTable',
                                    '|', 'undo', 'redo']
                                }}
                                onChange={(e, editor) => {
                                  form.setFieldValue("company_overview", editor.getData());
                                }}
                              />
                          )
                        }}
                      </Field>
                              <FormHelperText style={{  color: errors.company_overview && "#F44336" }}>{errors.company_overview ? errors.company_overview: `Max word limit : ${values.company_overview.length ===0 ? "0" : getWordCount(values.company_overview)}/200`}</FormHelperText>
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              
              <div className={classes.btnroot}>
                <div style={{ textAlign: "end" }} className={classes.wrapper}>
                  <Button
                    style={{
                      borderRadius: "0 0 12px 12px",
                      marginTop: "-1rem",
                    }}
                    color="primary"
                    size="medium"
                    type="submit"
                    disabled={loading}
                    variant="contained"
                  >
                    {loading && (
                      <img
                        alt=""
                        src={loadingSpinner}
                        className={classes.buttonProgress}
                      />
                    )}
                    {mode === "edit" ? "Update" : "Create"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Page>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <SessionExpiredModal open={openSessionExpiredModal} />

      <AlertModal
        title={deleteFileType === "logoFile" ? "Are you sure you want to remove this Logo?" : deleteFileType === "overviewFile" ? "Are you sure you want to remove this Overview File?" : "Are you sure you want to remove this Full Report File?"}
        open={deleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => {
          if (deleteFileType === "logoFile") {
            handleDeleteSecurityLogo()
          } else if (deleteFileType === "overviewFile") {
            removeOverViewFile()
          } else {
            removeFullReportFile()
          }
        }}
        openLoading={spinner}
      >
      </AlertModal>
    </Root>
  );
}

import React from "react";
import { styled } from '@mui/material/styles';
import {
  Container,
  Grid,
  Typography,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Button,
  Divider,Box
} from "@mui/material";
// import ReactHtmlParser from "react-html-parser";
import loadingSpinner from 'src/images/loading-spinner.gif'
import Tooltip from '../../components/Tooltip/tooltip'
import InfoIcon from '@mui/icons-material/Info';
import clsx from "clsx";

const PREFIX = 'InvoiceForm';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  labelTitle: `${PREFIX}-labelTitle`,
  grid: `${PREFIX}-grid`,
  details: `${PREFIX}-details`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  table1: `${PREFIX}-table1`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    margin: "0 auto",
    // display:'flex',
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    fontWeight: 600,
    fontSize: "1.5rem",
    margin: "3rem 0 1rem",
    textAlign: "left",
  },

  [`& .${classes.labelTitle}`]: {
    [theme.breakpoints.up(576)]: {
      width: "35%",
    },
    lineHeight: "1.6rem",
    width: "50%",
    textAlign: "left",
    fontWeight: "500",
  },

  [`& .${classes.grid}`]: {
    border: "1px solid #E6E6E6",
    padding: "1rem",
  },

  [`& .${classes.details}`]: {
    textAlign: "left",
    alignItems: "left",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  },

  [`& .${classes.table1}`]: {
    "& table,th,td": {
      borderBottom: "1px solid #ddd",
    },
    "& th": {
      color: "#ffffff",
      padding: "6px",
      fontWeight: 500,
      lineHeight: "1rem",
      whiteSpace: "nowrap",
      backgroundColor: "#6C6B74",
    },
    "& table": {
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",

      borderSpacing: 0,
    },
    "& th,td": {
      padding: 8,
      whiteSpace: "nowrap",
    },
  }
}));
const InvoiceForm = (props) => {
  const values = props.data
  
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
};
  const renderTooltipTitle =((item)=>{
    return(
      <Box style={{  margin: 0, padding: "10px" }}>
      <Box className={classes.table1}>
<table>
<tr>
<td  style={{fontWeight:500}}>Name</td>
      <td  style={{fontWeight:500, margin:'1rem'}}>Qty</td>
      <td  style={{fontWeight:500}}>Price</td>
</tr>
{item.procuredBy?.map((item)=>{
    return(
      <tr key={item.name.lable}>
      <td>{item.name.lable}</td>
      <td  style={{ marginLeft:'1rem'}}>{item.value}</td>
      <td  >{item.price}</td>

    </tr>
    )
  })}
</table>
     

      </Box>
  
  </Box>
    )
  })
  return (
    <StyledContainer className={classes.container}>
      <Typography className={classes.title}>Order Details</Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={7}>
          <Typography
            style={{
              margin: "8px 0",
              textAlign: "left",
              fontWeight: "600",
              textDecoration: "underline",
            }}
          >
            Order Info
          </Typography>
          <Grid container style={{alignItems:"center",}}>
          <Grid item xs={3} className={classes.labelTitle}>
              {values.order_type === "SELL" ? "Investor" : "Vendor"}
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.investorOrVendor?.lable}
            </Grid>
          {
           values.is_nse_trade &&values.investorOrVendor?.type ==="COMPANY" ?  <>
              <Grid item xs={3} className={classes.labelTitle}>
              Authorized Person
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.authorizedPerson?.lable}
            </Grid>
            </> : null
          }
            <Grid item xs={3} className={classes.labelTitle}>
              {values.order_type === "SELL" ? "Seller" : "Buyer"}
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.order_owner?.lable}
            </Grid>
            
            <Grid item xs={3} className={classes.labelTitle}>
              Order Type
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.order_type}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              User Type
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.is_purchased_online ? "Online" : "Offline"}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Order Status
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.status.label}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Transaction Type
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.transaction_type}
            </Grid>
            {values.transaction_type !== "DIRECT" ? (
              <>
                <Grid item xs={3} className={classes.labelTitle}>
                  Channel Partner
                </Grid>
                <Grid item xs={9} className={classes.details}>
                  {values.channel_partners[0]?.lable ? values.channel_partners[0]?.lable : "NA"}
                </Grid>
              </>
            ) : null}
            {
              values.is_nse_trade ? 
              <>
               <Grid item xs={3} className={classes.labelTitle}>
                NSE Order
              </Grid>
              <Grid item xs={9} className={classes.details}>
                Yes
              </Grid>
              <Grid item xs={3} className={classes.labelTitle}>
                SPA Type
              </Grid>
              <Grid item xs={9} className={classes.details}>
                {values.spa_type === "1" ? "Single" :'Double' }
              </Grid>
              <Grid item xs={3} className={classes.labelTitle}>
                NSE seller 
              </Grid>
              <Grid item xs={9} className={classes.details}>
                {values.nse_seller_one}
              </Grid>
              {/* {values.spa_type === "2" ? 
              <>
              <Grid item xs={3} className={classes.labelTitle}>
              NSE seller two
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.nse_seller_two}
            </Grid>
              </>:null} */}
            </>: null
            }
            <Grid item xs={3} className={classes.labelTitle}>
              Lead Owner
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.lead_owner?.lable ? values.lead_owner.lable : "NA"}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Co Managed 1
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.co_managed_one?.lable ? values.co_managed_one?.lable : "NA"}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Co Managed 2
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.co_managed_two?.lable ? values.co_managed_two.lable : "NA"}
            </Grid>
            {
             values.seller_type ==='Others' && 
             <>
             <Grid item xs={3} className={classes.labelTitle}>
              Facilitator Name
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.facilitator?.lable ? values.facilitator?.lable : "NA"}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Facilitating Type
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.facilitating_type ==='1' ? "Sell" : values.facilitating_type ==='2' ? "Buy" : "Buy and Sell"}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
            Disclose Client Details
            </Grid>
            <Grid item xs={9} className={classes.details}>
              {values.disclose_client_details ? "Yes": "No"}
            </Grid>
             </>
            }
          
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Typography
            style={{
              margin: "8px 0",
              textAlign: "left",
              fontWeight: "600",
              textDecoration: "underline",
            }}
          >
            Payment Details
          </Typography>

          <Grid container style={{alignItems:"center"}}>
            <Grid item xs={4} className={classes.labelTitle}>
              Order Date
            </Grid>
            <Grid item xs={8} className={classes.details}>
              {values.transaction_date ? values.transaction_date : "NA"}
            </Grid>
            <Grid item xs={4} className={classes.labelTitle}>
              Payment Date
            </Grid>
            <Grid item xs={8} className={classes.details}>
              {values.payment_date ?  values.payment_date: "NA"}
            </Grid>
            
          {(values.transaction_type ==="INTERMEDIATE" || values.is_nse_trade) &&
            <>
            <Grid item xs={4} className={classes.labelTitle}>
              Commission Status
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.commissionStatus  ? (values.is_nse_trade ? "Received" :"Paid") : "Not Paid"}
            </Grid>
            <Grid item xs={4} className={classes.labelTitle}>
              Commission {values.is_nse_trade ? "Received" :"Paid"} date
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.commissionStatus  ? values.commission_paid_date : "NA"}
            </Grid>
            </>}
            {(values.transaction_type ==="INTERMEDIATE") &&
            <>
            <Grid item xs={4} className={classes.labelTitle}>
              Commission Split type
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.commission_split_type ="1" ? "Percentage" : "Value"}
            </Grid>
            </>}
            <Grid item xs={4} className={classes.labelTitle}>
              Payment Mode
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.payment_mode === "1" ? "Online" : "Offline"}
            </Grid>
            
            <Grid item xs={4} className={classes.labelTitle}>
             Referred By
            </Grid>
            <Grid item xs={8} className={classes.details}
            >
            {values.referral?.lable ? values.referral?.lable : "NA"}
            </Grid>
            <Grid item xs={4} className={classes.labelTitle}>
             Referral Amount
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.referral_amount ? `₹${values.referral_amount}` : "NA" }
            </Grid>
            <Grid item xs={4} className={classes.labelTitle}>
             Tax
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.tdstcsValue?.TDS ? `₹${values.tdstcsValue?.TDS} TDS (${values.tdstcsValue?.tcsOrTdsPercentage}%)` : values.tdstcsValue?.TCS ? `₹${values.tdstcsValue?.TCS} TCS (${values.tdstcsValue?.tcsOrTdsPercentage}%)` : "₹0.00" }
            </Grid>
            <Grid item xs={4} className={classes.labelTitle}>
             Total Amount
            </Grid>
            <Grid item xs={8} className={classes.details}>
            {values.totalAmount === "₹0.000" ? (values.order_type === "BUY" &&
                  values.transaction_type === "INTERMEDIATE"
                    ? values.buyTotalPrice(values.order_type)
                    : values.totalConsolidatedPrice) :values.totalAmount}
            </Grid>
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              margin: "1rem 0px 0px",
              textAlign: "left",
              fontWeight: "600",
              textDecoration: "underline",
            }}
          >
            { `Bank details` }
          </Typography>
          <Grid container style={{alignItems:"center",}}>
          <Grid item xs={3} className={classes.labelTitle}>
          {/* { `${values.order_type === "SELL" ? "Investor" : "Vendor"} Bank details` } */}
          </Grid>
          <Grid item xs={2} className={classes.labelTitle}>
              Name
            </Grid>
            <Grid item xs={2} className={classes.labelTitle}>
            Bank Name
            </Grid>
            <Grid item xs={2} className={classes.labelTitle}>
              Account Number
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              IFSC Code
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
            {`${values.order_type === "SELL" ? "Investor" : "Vendor"}` }
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.bank_id.name}
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.bank_id.bankName}
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.bank_id.accountNumber}
            </Grid>
            <Grid item xs={3} className={classes.details}>
              {values.bank_id.ifsc}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
            {`${values.order_type === "SELL" ? "Seller" : "Buyer"}` }
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.order_owner_bank_id.name}
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.order_owner_bank_id.bankName}
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.order_owner_bank_id.accountNumber}
            </Grid>
            <Grid item xs={3} className={classes.details}>
              {values.order_owner_bank_id.ifsc}
            </Grid>
           {values.seller_type ==='Others' &&
           <>
            <Grid item xs={3} className={classes.labelTitle}>
              Facilitator
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_bank_id.name}
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_bank_id.bankName}
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_bank_id.accountNumber}
            </Grid>
            <Grid item xs={3} className={classes.details}>
              {values.facilitator_bank_id.ifsc}
            </Grid>
            </>}
          
            <Divider />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              margin: "1rem 0px 0px",
              textAlign: "left",
              fontWeight: "600",
              textDecoration: "underline",
            }}
          >
            { `Demat details` }
          </Typography>
          <Grid container style={{alignItems:"center",}}>
          <Grid item xs={3} className={classes.labelTitle}>
          {/* { `${values.order_type === "SELL" ? "Investor" : "Vendor"} Bank details` } */}
          </Grid>
            <Grid item xs={2} className={classes.labelTitle}>
            Demat with
            </Grid>
            <Grid item xs={2} className={classes.labelTitle}>
              Dp Id
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
              Client Id
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
            {`${values.order_type === "SELL" ? "Investor" : "Vendor"}` }
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.demat_id.dp_with}
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.demat_id.dp_id}
            </Grid>
            <Grid item xs={3} className={classes.details}>
              {values.demat_id.client_id}
            </Grid>
            <Grid item xs={3} className={classes.labelTitle}>
            {`${values.order_type === "SELL" ? "Seller" : "Buyer"}` }
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.order_owner_demat_id.dp_with}
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.order_owner_demat_id.dp_id}
            </Grid>
            <Grid item xs={3} className={classes.details}>
              {values.order_owner_demat_id.client_id}
            </Grid>
            {values.seller_type ==='Others' &&
           <>
            <Grid item xs={3} className={classes.labelTitle}>
              Facilitator
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_demat_id.dp_with}
            </Grid>
            
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_demat_id.dp_id}
            </Grid>
            <Grid item xs={2} className={classes.details}>
              {values.facilitator_demat_id.client_id}
            </Grid>
            </>}
          
            <Divider />
          </Grid>
        </Grid>
        <br/>
        <br/>

        <Grid
          item
          xs={12}
          style={{ borderBottom: "1px solid lightGrey", margin: "1rem 3px" }}
        ></Grid>
        <Typography
          style={{ margin: "8px 0", textAlign: "left", fontWeight: "600" }}
        >
          {" "}
          Scrip Details
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} style={{ color: "#314b8f" }}>
            {(values.transaction_type === "INTERMEDIATE") ? (
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Sl No</TableCell>
                  <TableCell className={classes.tableCell}>
                    Scrip Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>Quantity</TableCell>
                  {values.order_type === "SELL" && (
                    <TableCell className={classes.tableCell}>
                      Buy Price
                    </TableCell>
                  )}
                  <TableCell className={classes.tableCell}>
                    {values.order_type === "SELL"
                      ? "Sold/Client Price"
                      : "Buy/Client Price"}
                  </TableCell>
                  {values.seller_type === "Others" && <TableCell className={classes.tableCell}>
                                  Facilitator Price
                                </TableCell>}
                  <TableCell className={classes.tableCell}>RM Price</TableCell>
                  {values.order_type === "SELL" &&  <TableCell className={classes.tableCell}>
                                  Procured By
                                </TableCell>}
                  <TableCell className={classes.tableCell}>
                    Total Amount
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Sl No</TableCell>
                  <TableCell className={classes.tableCell}>
                    Scrip Name
                  </TableCell>
                  <TableCell className={classes.tableCell}>Quantity</TableCell>
                  {values.order_type === "SELL" && (
                    <TableCell className={classes.tableCell}>
                      Buy Price
                    </TableCell>
                  )}
                    {values.seller_type === "Others" && <TableCell className={classes.tableCell}>
                                  Facilitator Price
                                </TableCell>}
                  <TableCell className={classes.tableCell}>Price</TableCell>
                  {values.order_type === "SELL" &&  <TableCell className={classes.tableCell}>
                                  Procured By
                                </TableCell>}
                  <TableCell className={classes.tableCell}>
                    Total Amount
                  </TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {(values.transaction_type === "INTERMEDIATE" ||values.seller_type ==="Others")
                ? values.securityTableData?.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell className={classes.tableCell}>
                          {i + 1}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.security_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.quantity}
                        </TableCell>
                        {values.order_type === "SELL" && (
                          <TableCell className={classes.tableCell}>
                            {item.buyPrice}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {Number(item.price).toFixed(3)}
                        </TableCell>
                        { values.seller_type === "Others" && <TableCell className={classes.tableCell}>
                                      {item.facilitatorPrice}
                                    </TableCell>}
                        <TableCell className={classes.tableCell}>
                          {Number(item.commission).toFixed(3)}
                        </TableCell>
                        {values.order_type === "SELL" &&  <TableCell >
                                    {item.procuredBy?.length>0 ?  <Tooltip title={renderTooltipTitle(item)}> 

                                      <InfoIcon/>
                                     </Tooltip> : <Typography style={{marginLeft:'8px'}}>-</Typography>}
                                    </TableCell>}
                        <TableCell className={classes.tableCell}>
                          {Number(item.consolidated_price).toFixed(3)}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : values.securityTableData?.map((item, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell className={classes.tableCell}>
                          {i + 1}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.security_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {item.quantity}
                        </TableCell>
                        {values.order_type === "SELL" && (
                          <TableCell className={classes.tableCell}>
                            {item.buyPrice}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          {Number(item.price).toFixed(3)}
                        </TableCell>
                        { values.seller_type === "Others" && <TableCell className={classes.tableCell}>
                                      {item.facilitatorPrice}
                                    </TableCell>}
                        {values.order_type === "SELL" &&  <TableCell >
                                    {item.procuredBy?.length>0 ?  <Tooltip title={renderTooltipTitle(item)}> 

                                      <InfoIcon/>
                                     </Tooltip> : <Typography style={{marginLeft:'8px'}}>-</Typography>}
                                    </TableCell>}
                        <TableCell className={classes.tableCell}>
                          {Number(item.consolidated_price).toFixed(3)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {(values.transaction_type === "INTERMEDIATE") && (
                <>
                  {values.order_type === "BUY" && (
                    <TableRow>
                      {/* <TableCell rowSpan={3} /> */}
                      <TableCell colSpan={3} />

                      <TableCell
                        style={{
                        //   color: "#314b8f",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        Total Client Price
                      </TableCell>
                      <TableCell
                        style={{
                        //   color: "#314b8f",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {values.totalConsolidatedPrice}
                      </TableCell>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={3} />

                    <TableCell
                      style={{
                        // color: "#314b8f",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                    Total RM Commission
                    </TableCell>
                    <TableCell
                      style={{
                        // color: "#314b8f",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      ₹
                      {(parseFloat(
                        values.totalCommissionPrice(values.order_type)
                      ).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} />
                  </TableRow>
                </>
              )}
              {values.seller_type ==='Others' &&
                (<TableRow>
                    <TableCell colSpan={3} />

                    <TableCell
                      style={{
                        // color: "#314b8f",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                    Total Facilitated Commission
                    </TableCell>
                    <TableCell
                      style={{
                        // color: "#314b8f",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      ₹
                      {(parseFloat(
                        values.totalFacilitatedCommissionPrice(values.order_type)
                      ).toFixed(3))}
                    </TableCell>
                    <TableCell colSpan={3} />
                  </TableRow>)
              }

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />

                <TableCell
                  style={{
                    // color: "#314b8f",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {values.order_type === "SELL"
                    ? "Total Sold Price"
                    : "Total Buy Price"}
                </TableCell>
                <TableCell
                  style={{
                    // color: "#314b8f",
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {values.order_type === "BUY" &&
                  values.transaction_type === "INTERMEDIATE"
                    ? values.buyTotalPrice(values.order_type)
                    : values.totalConsolidatedPrice}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {values.transaction_type === "INTERMEDIATE" &&
        <>
        <Typography my={2} sx={{fontWeight:'bold', textAlign:'left'}}>Channel partner Commission report</Typography>
        <TableContainer  component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
                Channel Partner
              </TableCell>
              <TableCell>
                Commission Value
              </TableCell>
              <TableCell>
                Commission Percentage
              </TableCell>
              <TableCell>
                Commission Paid 
              </TableCell>
              <TableCell>
                Commission Paid Date
              </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                  {values.channel_partner_report.map((item)=>{
                    return(
                      <TableRow>
                        <TableCell>
                          {item.name}
                        </TableCell>
                        <TableCell>
                          {item.commission_value}
                        </TableCell>
                        <TableCell>
                          {item.commission_percentage}
                        </TableCell>
                        <TableCell>
                          {item.commission_paid_status ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {item.commission_paid_date ? item.commission_paid_date :"NA" }
                        </TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        </>
}

        <Grid
          item
          xs={1}
          
        >
            <Typography style={{marginTop:'1rem', fontWeight:600}}>Remarks :</Typography>
        </Grid>
        <Grid
          item
          xs={11}
          style={{marginTop:'1.2rem',}}
          className={clsx(classes.details, classes.table1)}
        >
                      <div dangerouslySetInnerHTML={createMarkup(values.remarks)} />

            {/* {ReactHtmlParser(values.remarks)} */}
     </Grid>
      </Grid>
      <div style={{ margin: "1rem" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={props.onConfirm}
          style={{ marginLeft: "1rem", textTransform: "none" }}
        >
          {props.loading && <img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} />}
         Confirm Order
        </Button>
        <Button
          onClick={props.onClose}
          color="primary"
          variant="outlined"
          style={{ marginLeft: "1rem", textTransform: "none" }}
        >
          Close
        </Button>
      </div>
      
    </StyledContainer>
  );
};

export default InvoiceForm;

import axios from 'axios'
import { helpers } from 'src/_helpers'

let cancelToken = axios.CancelToken;
let cancel;


 
export const serviceOrderService = {
    getServiceOrder,
    getOnlineServiceOrder,
    getLatestServiceOrder,
    getServiceOrderByID,
    postServiceOrder,
    getSecurityByID,
    getVerifiedData,
    putVerifiedData,
    putAssigToOrder,
    putStatusOfServiceOrder,
    postServiceOrderDocuments,
    getServiceOrderDocuments,
    deleteServiceOrderDocuments,
    getServiceOrderdDealTermDocuments,
    putServiceOrderData,
    postMappingServiceOrder,
    getServiceOrderByClientIdAndSecurityId,
    getServiceOrderByUserID,
    updateDealTermSignatureStauts,
    getInvoiceDocument,
    updateRemarks,
    exportOrders,
    generateMIS,
    deleteServiceOrder,
    getAllPartnerOrder,
    getDeletedOrders,
    recoverOrders,
    uploadBrandingImage,
    updateIndividualSeviceOrder,
    getFutureOrders,
    updateFutureOrderTaxValue,
    getInvoiceOrders,
    getOrderDetails,
    generateInvoicePDF,
    generateDealTerm,
    getNseMailHistory,
    sendNseEmail,
    getEscrowDetailsOfServiceOrder,
    updateCommissionStatus,
    getVouchReport,
    getCommissionData,
    commissionUpdate,
    requestOrderUpdate,
    putStatusOfOnlineServiceOrder,
    deleteProcuredBy,
    getDocumentData,
    getDocumentId,
    generateBulkDealTerm,
    getCounterSignDealtermReport,
    csdlUpdate,
    getReferralData,
    bulkReferralUpdate,
    verifyOtpforDealterm,
    getServiceOrderdDealTermTemplate,
    generateSignedDealTerm,
    generateSignedEmpanelment,
    generateBulkSignedDealTerm,
    sendEmailforDealterm,
    postServiceOrderDocumentsForClient,
    getStatusReport,
    getEmailSentHistoryForDealTerm,
    deleteDealtermMailHistory,
    generateEmplanelmentDocument,
    sendEmailforMultipleOrderDealterm,
    getServiceOrderdMultipleOrderDealTermTemplate,
    getDealtermOrders,
    getUserInfo,
    getNSEDocuments,
    getNSEDocumentNameList,
    setAdditionalAnnexure,
    getAdditionalAnnexure,
    generateAdditionalAnnexure
};

async function setAdditionalAnnexure(data) {
    // helpers.setHeader();
    return axios.post(`/additional_annexure_documents`,data)
        .then(data => {
            return data
        })  
        .catch(error => {
            return error.response
        })
}

async function getAdditionalAnnexure(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/additional_annexure_documents/${service_order_id}`,requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)
}

async function generateAdditionalAnnexure(data) {
    // helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.post(`/generate_annexure_documents`,data,requestOptions)
        .then(data => {
            return data
        })  
        .catch(error => {
            return error.response
        })
}

async function postServiceOrderDocumentsForClient(PostServiceOrderDocumentData) {
    // helpers.setHeader();
    return axios.post(`/service_order_document_for_dealterm`,
        PostServiceOrderDocumentData)
        .then(data => {
            return data
        })  
        .catch(error => {
            return error.response
        })
}



async function getServiceOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getNSEDocumentNameList() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/nse_document_name_list`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getAllPartnerOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllPartnerOrder`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getOnlineServiceOrder(data) {
    
    if (cancel) {
        cancel(); // Cancel the request
      }
    const source = cancelToken.source();
    cancel = source.cancel; 
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_all_order`,data, { cancelToken: source.token }, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function getServiceOrderByUserID(userid) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_by_userid/${userid}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getUserInfo(userid) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/get_user_info/${userid}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



async function getEmailSentHistoryForDealTerm(orderId) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/email_history_for_dealterm/${orderId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getDealtermOrders(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/get_deal_term_orders/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}





async function getLatestServiceOrder() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/latest_service_order_status`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getServiceOrderByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function postServiceOrder(PostServiceOrderData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_order`,
        JSON.stringify(PostServiceOrderData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function postMappingServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/service_order/sell_buy_order_map`,
        JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


async function postServiceOrderDocuments(PostServiceOrderDocumentData) {
    helpers.setHeader();
    return axios.post(`/service_order_document`,
        PostServiceOrderDocumentData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}



async function getSecurityByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/security_list?service_order_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getVerifiedData(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order/verify_detail?service_order_id=${service_order_id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function putVerifiedData(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/verify_detail`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function putAssigToOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/set_assign_to_order`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function putStatusOfServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/status`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function putStatusOfOnlineServiceOrder(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order/online_status`,JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function getServiceOrderDocuments(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_document/${service_order_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


async function deleteServiceOrderDocuments(service_order_id){
    helpers.setHeader();
    return axios.delete(`/service_order_document_file/${service_order_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function deleteDealtermMailHistory(id){
    helpers.setHeader();
    return axios.delete(`/email_history_for_dealterm/${id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function deleteServiceOrder(service_order_id){
    helpers.setHeader();
    return axios.post(`/deleteServiceOrder/${service_order_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function getNSEDocuments(url,service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/${url}/${service_order_id}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)
}


async function getServiceOrderdDealTermDocuments(service_order_id) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/deal_term_server_order/${service_order_id}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)
}

async function putServiceOrderData(serviceOrderData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/service_order`,
        JSON.stringify(serviceOrderData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function getServiceOrderByClientIdAndSecurityId(security_id,client_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/service_order_by_security/?security_id=${security_id}&client_id=${client_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


async function getInvoiceDocument(order_id) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/invoice_server_order/${order_id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

async function updateDealTermSignatureStauts(investor_code,data,s1) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/updateDealTermSignatureStauts/?service_order_id=${data}&status=${s1}&investor_code=${investor_code}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}




async function updateRemarks(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/update_orderRemarks`,
        JSON.stringify(data), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function exportOrders(data) {
    helpers.setHeader();
    return axios.post(`/sendOrderDetailsToEmail`,
    data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function generateMIS(data) {
    helpers.setHeader();
    return axios.post(`/generateMIS`,
    data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


async function getDeletedOrders() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getDeletedOrders`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function recoverOrders(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/recoverServiceOrder/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function uploadBrandingImage(formData) {
    helpers.setHeader();
    return axios.post(`/uploadCustomBranding`,
    formData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function updateIndividualSeviceOrder(data){
    helpers.setHeader();
    return axios.post(`/updateOrderTransaction`,data)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}


async function getFutureOrders(order_id,user_id,order_type) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getNextServiceOrders?id=${order_id}&investor_id=${user_id}&order_type=${order_type}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function updateFutureOrderTaxValue(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateTcsOrTdsValues`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getInvoiceOrders(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getOrdersForInvoice`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getOrderDetails(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/geInvoiceDatafromOrderId`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function generateInvoicePDF(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generateInvoiceFromJSon`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getNseMailHistory(order_id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getNseMailSentHistory/?order_id=${order_id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function sendNseEmail(data) {
    helpers.setHeader();
    return axios.post(`/sendNseEmailsById`,data)
        .then(data => data)
        .catch(error => error.response)
}


async function getEscrowDetailsOfServiceOrder(id) {
    helpers.setHeader();
    return axios.post(`/getEscrowDetailsOfServiceOrder/?service_order_id=${id}`)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function updateCommissionStatus(data) {
    helpers.setHeader();
    return axios.post(`/updateCommissionStatusAndDate`,data)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

async function getVouchReport(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/exportVouchReport`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getDocumentData(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getDocumentData`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getStatusReport(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getStatusReport`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getDocumentId(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getDocumentId`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}




async function getCommissionData(payload) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getbulkCommissionData`,payload, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getReferralData(payload) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getBulkReferralData`,payload, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function commissionUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/bulkCommissionUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function csdlUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/csdlUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function bulkReferralUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/bulkReferralUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function requestOrderUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/requestForOrderUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function generateDealTerm(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generateClientSpecificDealterm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function generateEmplanelmentDocument(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generate_empanelment`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function deleteProcuredBy(procuredBy_id){
    helpers.setHeader();
    return axios.delete(`/delete_procuredby/${procuredBy_id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

async function verifyOtpforDealterm(data){
    // helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/verfiy_otp_for_dealterm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
async function getServiceOrderdDealTermTemplate(service_order_id) {
    try {
       // helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/deal_term_server_order_template/${service_order_id}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)  
    } catch (error) {
        
    }
   
}
async function getServiceOrderdMultipleOrderDealTermTemplate(service_order_id) {
    try {
       // helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
    
        }
    };
    return axios.get(`/deal_term_server_order_template_multiple/${service_order_id}`, requestOptions)
        .then(res => {
            return res})
        .catch(error => error.response)  
    } catch (error) {
        
    }
   
}
async function generateSignedDealTerm(data) {
    helpers.setHeaderForSignature();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`https://ukuat.qapita.dev/api/generateSignedDealTerm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function generateSignedEmpanelment(data) {
    helpers.setHeaderForSignature();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`https://ukuat.qapita.dev/api/generateSignedEmpanelment`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



async function generateBulkSignedDealTerm(data) {
    helpers.setHeaderForSignature();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generateBulkSignedDealTerm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function sendEmailforDealterm(data) {
    // helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/send_email_for_dealterm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function sendEmailforMultipleOrderDealterm(data) {
    // helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/send_email_for_multiple_order_dealterm`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


async function generateBulkDealTerm(data) {
    helpers.setHeader();
    const requestOptions = {
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/generate_bulk_deal_term`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function getCounterSignDealtermReport(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/counter_signed_dealterm_report`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
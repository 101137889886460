/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import { userService } from "src/_services/users";
import FormLabel from "@mui/material/FormLabel";
import { Formik, Field, FieldArray, getIn } from "formik";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Grid,  FormGroup, Checkbox, Typography,OutlinedInput ,Box,Chip} from "@mui/material";
import Button from "@mui/material/Button";

import Paper from "@mui/material/Paper";
import { useNavigate, useLocation } from "react-router-dom";
import ModalCentered from "src/components/Modals/ModalCentered";
import NormalCard from "src/components/Cards/NormalCard";
import theme from "src/theme";
import { getLookUpDataService } from "src/_services/lookUp";
import ServiceOrderCreateForm from "./ServiceOrderCreateForm";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { serviceOrderService } from "src/_services/serviceOrder";
import { connect } from "react-redux";
import loadingSpinner from 'src/images/loading-spinner.gif'
// import settings from 'src/settings'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import TcsOrTdsModal from "src/components/Modals/TcsOrTdsModal";
import moment from "moment";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InvoiceForm from "./InvoiceForm";
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '../../components/Tooltip/tooltip'
import TaxModel from "./TaxModel";
import compareJSON from 'src/components/comparision';

const PREFIX = 'ServiceOrderCreate';

const classes = {
  root: `${PREFIX}-root`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  gridFlex: `${PREFIX}-gridFlex`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  table1: `${PREFIX}-table1`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 16px 16px 0",
    padding: "10px",
  },

  [`& .${classes.table}`]: {
    minWidth: 340,
  },

  [`& .${classes.tableCell}`]: {
    paddingBottom: 5,
    paddingTop: 5,
    color: "#314b8f",
  },

  [`& .${classes.gridFlex}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  },

  [`& .${classes.table1}`]: {
    "& table,th,td": {
      borderBottom: "1px solid #ddd",
    },
    "& th": {
      color: "#ffffff",
      padding: "6px",
      fontWeight: 500,
      lineHeight: "1rem",
      whiteSpace: "nowrap",
      backgroundColor: "#6C6B74",
    },
    "& table": {
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",

      borderSpacing: 0,
    },
    "& th,td": {
      padding: 8,
      whiteSpace: "nowrap",
    },
  }
}));

const DefaultUnlistedAccount =  localStorage.getItem('DefaultUnlistedAccount') !== 'undefined' ? JSON.parse(localStorage.getItem('DefaultUnlistedAccount')) : null

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateServiceOrder = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [channelPartner, setChannelPartner] = React.useState([]);
  const [salesAndOperation, setSalesAndOperation] = React.useState([]);
  const [transactionType, setTransactionType] = React.useState("");
  const [vendorDematAccountList, setVendorDematAccountList] = React.useState(
    []
  );
  const [userID, setUserID] = useState(null)
  const [vendorBankAccountList, setVendorBankAccountList] = React.useState([]);
  const [sellerDematAccountList, setSellerDematAccountList] = React.useState([]);
  const [sellerBankAccountList, setSellerBankAccountList] = React.useState([]);
  const [facilitatorBankAccountList, setFacilitatorBankAccountList] = React.useState([]);
  const [facilitatorDematAccountList, setFacilitatorDematAccountList] = React.useState([]);
  const [serviceOrderType, setServiceOrderType] = React.useState("");
  const [serviceOrderStatus, setServiceOrderStatus] = React.useState([]);
  let [securityData, setSecurityData] = React.useState([]);
  const [loading, setLoading] = useState(false)
  const [formikValues,setFormikValues]= useState(false)
  const [investorOrVendor, setInvestorOrVendor] = useState([])
  let [securityTableData, setSecurityTableData] = React.useState([]);
  let [consolidatedPrice, setConsolidatedPrice] = React.useState(0);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [totalPreviousOrderSum, setTotalPreviousOrderSum] = useState(0)
  const [totalTcsOrTdsPaidValue, setTotalTcsOrTdsPaidValue] = useState(0)
  const [showTcsOrTds, setShowTcsOrTds] = useState(false)
  const [openModalForTcsAndTds, setOpenModalForTcsAndTds] = useState(false)
  const [tcsTdsApplicableSum, setTcsTdsApplicableSum] = useState(0)
  const [tdsValue, setTdsValue] = useState(0)
  const [tcsValue, setTcsValue] = useState(0)
  const [editItem,setEditItem] = useState(false)
  const [callTaxApi,setCallTaxAPi] = useState(false)
  const [ totalTransactionValue,setTotalTransactionValue] = useState(null)
  const [ futureOrders,setFutureOrders] = useState([])
  const [ openTax,setOpenTax] = useState(false)
  const [ nseSellers,setNseSellers] = useState([])
  const [orderStatus,setOrderStatus] = useState([])
  const [isNSE,setIsNSE] = useState(false)
  const [totalOrderValue,setTotalOrderValue] = useState(0)
  const [invoiceDialog,setInvoiceDialog] = useState(false)
  const [verifyError,setVerifyError] = useState(false)
  const [ulkAuthPersons,setUlkAuthPersons] = useState([])
  const [clientAuthPersons,setClientAuthPersons] = useState([])



  let query = useQuery();
  const mode =  (props?.id ?  'edit' : query.get("mode") )|| null
  const [tdsTcsInitialValue, setTdsTcsInitialValue] = useState({
    TDS: 0,
    TCS: 0,
    taxType: "TCS",
    tcsOrTdsPercentage: 0.1
  })

  let [finalOrderPrice, setFinalOrderPrice] = React.useState(0);
  //
  const [serviceOrderBasicData, setServiceOrderBasicData] = React.useState({
    transaction_type: "",
    channel_partners: [],
    order_type: "",
    investorOrVendor: "",
    order_owner:DefaultUnlistedAccount && DefaultUnlistedAccount[1],
    status: {
      code: "ACTIVE",
      label: "Active",
    },
    authorizedPerson:"",
    seller_type:'Unlistedkart',
    spa_type:"0",
    nse_seller_one:"",
    nse_seller_two:"",
    payment_date: '',
    payment_mode: '',
    is_nse_trade:false,
    transaction_date: '',
    lead_owner: { code: props.authReducer.auth.user.user_unique_code, lable: props.authReducer.auth.user.display_name } || "",
    co_managed_one: "",
    co_managed_two: "",
    assign: { code: props.authReducer.auth.user.user_unique_code, lable: props.authReducer.auth.user.display_name } || "",
    demat_id: "",
    bank_id: "",
    order_owner_demat_id: "",
    order_owner_bank_id: "",
    facilitator_bank_id:"",
    facilitator_demat_id:"",
    facilitator:"",
    referral: "",
    facilitating_type:0,
    referral_amount: null,
    is_purchased_online: false,
    remarks: "",
    mode: mode,
    is_tcs_tds_collected_value: 0,
    is_tcs_tds_collected: 0,
    commissionStatus:false,
    commission_paid_date:"",
    channel_partner_report:[],
    commission_split_type:"1",
    referral_paid_status:false,
    referral_paid_date:null,
    disclose_client_details:false,
    ulk_authorized_person:"",
    spa_date:'',
    stage1_document_date:''
  })
const role = props.authReducer.auth.user.default_role_code 
  const navigate = useNavigate();
  let location = useLocation();
  const timer = React.useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };




  useEffect(() => {

    let data= { 
      TCS:securityTableData[0]?.is_tcs_tds_collected  === 1 ?securityTableData[0]?.is_tcs_tds_collected_value : 0,
      taxType: securityTableData[0]?.is_tcs_tds_collected  === 2 ? "TDS" : "TCS",
      TDS:securityTableData[0]?.is_tcs_tds_collected  === 2 ?securityTableData[0]?.is_tcs_tds_collected_value : 0,
      tcsOrTdsPercentage: 0.1
    }
    setTdsTcsInitialValue(data)
    updateTcsOrTdsValue(data)
    sumOfConsolidated() 
  }, [securityTableData,totalTcsOrTdsPaidValue])

  let sumOfConsolidated = (value) => {
    if(query.get("mode") === null && !props.id){
      setFinalOrderPrice(0)
      setTcsValue(0)
      setTdsValue(0)
    }
  
    let sumConsolidatedPrice = securityTableData.reduce((count, item) => {
      return count + Number(item.consolidated_price);
    }, 0);
    let sumConsolidatedPriceForNse = securityTableData.reduce((count, item) => {
      return count +( item.is_tcs_tds_collected ? Number(item.documentationCharges) + Number(item.stampDutyFee) : 0) + Number(item.consolidated_price);
    }, 0);


    let securityArray = securityTableData.map((item) => {
      let procuredBy =item?.procuredBy?.map((items) =>  { 
        return{
        code:items.name.code, quantity:items.value,procured_price:items.price
      }
    })
      return {
        id: item.id,
        price: Number(item.price),
        commission: Number(item.commission),
        order_supplies: item.order_supplies,
        quantity: item.quantity,
        buyPrice:item.buyPrice,
        facilitator_price:item.facilitatorPrice,
        procuredBy:JSON.stringify(procuredBy),
        documentation_charges:item.documentationCharges ? item.documentationCharges : 0,
        stamp_duty_fee:item.stampDutyFee
      };
    });
    setConsolidatedPrice(sumConsolidatedPriceForNse.toFixed(3));
      const values = serviceOrderBasicData
      if ((parseFloat(sumConsolidatedPrice.toFixed(3)) + parseFloat(totalPreviousOrderSum)) > 5000000 || (parseFloat(sumConsolidatedPrice.toFixed(3)) + parseFloat(totalTcsOrTdsPaidValue)) > 5000000) {
      if (totalTcsOrTdsPaidValue <= 5000000) {
        let taxValue = (parseFloat(totalTcsOrTdsPaidValue) + (parseFloat(sumConsolidatedPrice))) - 5000000
        let finalTaxValue = taxValue * (tdsTcsInitialValue.tcsOrTdsPercentage /100)
        values.is_tcs_tds_collected_value = finalTaxValue
        // if(value){
        //   updateServiceOrder(values,true) 
        // }
        setTcsTdsApplicableSum(taxValue)
      } else {
        let finalTaxValue = sumConsolidatedPrice * (tdsTcsInitialValue.tcsOrTdsPercentage /100)
        values.is_tcs_tds_collected_value = finalTaxValue
        // if(value){
        //   updateServiceOrder(values,true) 
        // }
        setTcsTdsApplicableSum(sumConsolidatedPrice)
      }

      // setTimeout(() => {
      //   setOpenModalForTcsAndTds(true)
      //   setTitleModal("TCS and TDS applicable")
      // }, 2000)
      let sumConsolidatedPriceForNse = securityTableData.reduce((count, item) => {
        return count + Number(item.documentationCharges) + Number(item.stampDutyFee)  + Number(item.consolidated_price);
      }, 0);
      setConsolidatedPrice(sumConsolidatedPriceForNse)
      setShowTcsOrTds(true)
    } else {
      values.is_tcs_tds_collected_value = 0
      // if(value){
      //   updateServiceOrder(values,true) 
      // }
      let sumConsolidatedPriceForNSE = securityTableData.reduce((count, item) => {
        return count + Number(item.documentationCharges) + Number(item.stampDutyFee) + Number(item.consolidated_price);
      }, 0);
      setConsolidatedPrice(sumConsolidatedPriceForNSE.toFixed(3));
      setTcsTdsApplicableSum(0)
      //  setOpenModalForTcsAndTds(false)
      setShowTcsOrTds(false)
    }


    setSecurityData(securityArray);
  };
   
  const isHiddenScripExist = securityTableData.filter(obj => obj.security?.hide_trading_status === true).map((item)=> item.security);

  const getSecurityData = (value) => {
    serviceOrderService.getSecurityByID(props?.id ? props.id :location?.state?.userID)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const array = []
            // eslint-disable-next-line array-callback-return
            res.data.map((item) => {
              const newArray = item.procured_by?.map(itemB => {
                const matchingItemA = salesAndOperation.find(itemA => itemA.code === itemB.code);
                return {
                    name: {
                        code: matchingItemA.code,
                        lable: matchingItemA.lable
                    },
                    value: itemB.value,
                    price:itemB.procured_price,
                    id:itemB.id
                };
            });
              array.push({
                commision:item.commision,
                commission:  item.commision,
                facilitatorPrice:  item.facilitator_price,
                id: item.security_id,
                price: item.price,
                documentationCharges:item.documentation_charges,
                stampDutyFee:item.stamp_duty_charges,
                buyPrice:item.procurement_price,
                quantity: item.quantity,
                facilitator_price:item.facilitator_price,
                order_transaction_id:item.order_transaction_id,
                security_name: item.security_name,
                consolidated_price: item.price * item.quantity,
                is_tcs_tds_collected:item.is_tcs_tds_collected,
                is_tcs_tds_collected_value:item.is_tcs_tds_collected_value,
                procuredBy:newArray ? newArray : [],
                is_nse_trade:serviceOrderBasicData?.is_nse_trade
              })
            })
            setSecurityTableData(array)
            if(value){
              setCallTaxAPi(true)
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      }).catch((error) => {
        console.log(error);
      })

  }

  const updateTcsOrTdsValue = (value) => {
    setTcsValue(value.TCS)
    setTdsValue(value.TDS)
    let sumConsolidatedPrice = securityTableData.reduce((count, item) => {
      return count +( item.is_tcs_tds_collected ? (Number(item.stampDutyFee) + Number(item.documentationCharges) ):0) +  Number(item.consolidated_price);
    }, 0);
    if (value.TCS !== 0) {
      setFinalOrderPrice((parseFloat(sumConsolidatedPrice) + parseFloat(value.TCS)).toFixed(3));
    } else {
      setFinalOrderPrice(Math.abs((parseFloat(sumConsolidatedPrice) - parseFloat(value.TDS)).toFixed(3)));
    }
  }


  useEffect(() => {
    sumOfConsolidated();
  }, [securityTableData])

  useEffect(()=>{
    if(callTaxApi){
      setTimeout(() => {
      sumOfConsolidated('tax');  
      }, 1000);
    }
  },[callTaxApi])

  const getUserAccounts =(async(id,dematId,bankId)=>{
    const primaryVendorDemat =await  getVendorDematAccounts(id,'edit')
    const primaryVendorBank = await getVendorBankAccounts(id,'edit');
    const investorDemat = await primaryVendorDemat.find(item => item.id === dematId);
    const investorBank =await  primaryVendorBank.find(item => item.id === bankId);
    return ({
      bank:investorBank,
      demat:investorDemat
    })
    // setServiceOrderBasicData({...serviceOrderBasicData,bank_id:investorBank,demat_id:investorDemat})
  })

  const getUlkAccounts =(async(id,dematId,bankId)=>{
    const primaryVendorDemat =await  getSellerDematAccounts(id,'edit')
    const primaryVendorBank = await getSellerBankAccounts(id,'edit');
    const ulkDemat = await primaryVendorDemat.find(item => item.id === dematId);
    const ulkBank =await  primaryVendorBank.find(item => item.id === bankId);
    return ({
      bank:ulkBank,
      demat:ulkDemat
    })
  })

  const getFacilitatorAccounts =(async(id,dematId,bankId)=>{
    const primaryFacilitatorDemat =await  getSellerDematAccounts(id,'edit','facilitator')
    const primaryFacilitatorBank = await getSellerBankAccounts(id,'edit','facilitator');
    const ulkDemat = await primaryFacilitatorDemat.find(item => item.id === dematId);
    const ulkBank =await  primaryFacilitatorBank.find(item => item.id === bankId);
    return ({
      bank:ulkBank,
      demat:ulkDemat
    })
  })
  useEffect(() => {
   getOrderDetails()
  }, [investorOrVendor])

  const getOrderDetails =(()=>{
    if ((query.get("mode") === "edit" ||props?.id)&& investorOrVendor.length>0) {
      
     serviceOrderService.getServiceOrderByID(props?.id ? props.id : location?.state?.userID)
        .then(async (res) => {
          if (res) {
            if (res.status === 200) {
              const userType = res.data.order_type ==="Buy" ? res.data.related_vendor_id : res.data.related_investor_id
              setUserID(userType)
              getUlkAuthPersons(res.data.related_order_owner_id)
              getNSEAuthPersons(userType)
            const details =await  getUserAccounts(userType,res.data.demat_id,res.data.bank_id)
            const ulkDetails = await getUlkAccounts(res.data.related_order_owner_id,res.data.order_owner_demat_id,res.data.order_owner_bank_id)
            const facilitatorDetails = res.data.related_facilitator_id ? await getFacilitatorAccounts(res.data.related_facilitator_id,res.data.related_facilitator_demat_id,res.data.related_facilitator_bank_id) : null
            // const ownerBank =await  sellerBankAccountList.find(item => item.id === res.data.order_owner_bank_id);
             res.data.seller_type === 1 && getInvestorsTotalSellOrBuy(res.data.order_type_code ==="SELL" ?res.data.related_investor_id: res.data.related_vendor_id, res.data.order_type_code,props?.id ? props.id :location.state?.userID)
              let vendor_or_invester = {}
              if (res.data.order_type_code === "SELL") {
                vendor_or_invester = { code: res.data.investor_code, lable: `${res.data.investor} ( ${res.data.investor_code.toUpperCase()} )`,type:res.data.investor_user_type_id  ===2 ? "COMPANY" : "INDIVIDUAL"}
              } else {
                vendor_or_invester = { code: res.data.vendor_code, lable: `${res.data.vendor} ( ${res.data.vendor_code.toUpperCase()} )`,type:res.data.vendor_user_type_id  ===2 ? "COMPANY" : "INDIVIDUAL" }
              }
              let referralValue = {}
              if ((res.data.referral_code === null) && res.data.referral === null) {
                referralValue = ""
              } else {
                referralValue = { code: res.data.referral_code, lable: `${res.data.referral} ( ${res.data.referral_code.toUpperCase()} ) ` }
              }
              if(res.data.client_verified_status){
                const nseStatus = orderStatus.length>1  && orderStatus.filter((item)=>{
                  return  item.code ==="INACTIVE" || item.code ==="ACTIVE" 
                  })
                setServiceOrderStatus(nseStatus)
              }else if(res.data.is_nse_trade){
                const nseStatus = orderStatus.length>1  && orderStatus.filter((item)=>{
                  return  item.code !=="ONHOLD" && item.code !=="PREPROCESSING" &&  item.code !=="CANCELLED"
                  })
                setServiceOrderStatus(nseStatus)
              }
              setServiceOrderType(res.data.order_type_code || "",)
              let channel_partner = res.data.addtional_channel_partner ? res.data.addtional_channel_partner.map((item)=>{
                return({
                  code:item.code,
                  lable:`${item.name} ( ${item.code !== null ? item.code.toUpperCase() : ""} ) ${item.verified_status  ===1 ?  '✅' : '❌' }`
                })
              }) : null
                setServiceOrderBasicData({
                disclose_client_details:res.data.disclose_client_details,
                facilitating_type:res.data.facilitating_type ? JSON.stringify(res.data.facilitating_type) : "0",
                transaction_type: res.data.transaction_type_code || "",
                commission_split_type:res.data.commission_split_type ? JSON.stringify(res.data.commission_split_type) : res.data.transaction_type_code === "INTERMEDIATE" ? "1" :"0",
                channel_partner_report:res.data.commission_split_data ?res.data.commission_split_data : [] ,
                channel_partners: channel_partner ? channel_partner : [{ code: res.data.channel_partner_code, lable: `${res.data.channel_partner} ( ${res.data.channel_partner_code !== null ? res.data.channel_partner_code.toUpperCase() : ""} )` }],
                order_type: res.data.order_type_code || "",
                investorOrVendor: vendor_or_invester || "",
                order_owner: DefaultUnlistedAccount.concat(investorOrVendor).filter((item)=>item.id === res.data.related_order_owner_id)[0],
                status: {
                  code: res.data.order_status_code,
                  label: res.data.order_status,
                } || "",
                lead_owner: { code: res.data.lead_owner_code, lable: res.data.lead_owner } || "",
                co_managed_one: { code: res.data.co_managed_one_code, lable: res.data.co_managed_one } || "",
                co_managed_two: { code: res.data.co_managed_two_code, lable: res.data.co_managed_two } || "",
                assign: { code: res.data.assign_code, lable: res.data.assign } || "",
                demat_id: details.demat,
                bank_id:details.bank,
                payment_date: res.data.payment_date ? moment(res.data.payment_date).format("YYYY-MM-DD") : '',
                transaction_date: res.data.transaction_date ? moment(res.data.transaction_date).format("YYYY-MM-DD") : '',
                payment_mode: res.data.payment_mode ? JSON.stringify(res.data.payment_mode) : '',
                order_owner_demat_id: ulkDetails.demat,
                order_owner_bank_id:ulkDetails.bank,
                commissionStatus:res.data.commission_paid,
                commission_paid_date: res.data.commission_paid_date ? moment(res.data.commission_paid_date).format("YYYY-MM-DD") : '',
                referral: referralValue,
                referral_paid_status:res.data.referral_paid_status ? res.data.referral_paid_status : false, 
                referral_paid_date:res.data.referral_paid_date? moment(res.data.referral_paid_date).format("YYYY-MM-DD") : '',
                referral_amount: (res.data.referral_amount === null ? "" : parseFloat(res.data.referral_amount)),
                is_purchased_online: (res.data.is_purchased_online === (null || undefined) ? false : res.data.is_purchased_online),
                remarks: res.data.remarks || "",
                is_nse_trade:res.data.is_nse_trade ? res.data.is_nse_trade : false,
                spa_type:res.data.spa_type ? JSON.stringify(res.data.spa_type) : 0,
                nse_seller_one:res.data.nse_seller_one ? res.data.nse_seller_one : "",
                seller_type:res.data.seller_type === 1 ? "Unlistedkart" :'Others',
                facilitator: { code: res.data.facilitator_code,lable: `${res.data.facilitator} ( ${res.data.facilitator_code?.toUpperCase()} )` } || "",
                facilitator_bank_id:facilitatorDetails?.bank || null,
                facilitator_demat_id:facilitatorDetails?.demat || null,
                authorizedPerson:res.data.related_authorized_person ? JSON.parse(res.data.related_authorized_person) : '',
                ulk_authorized_person:res.data.ulk_authorized_person_for_nse ? JSON.parse(res.data.ulk_authorized_person_for_nse) : '',
                spa_date: res.data.spa_date ? moment(res.data.spa_date).format("YYYY-MM-DD") : "",
                stage1_document_date: res.data.stage1_document_date ? moment(res.data.stage1_document_date).format("YYYY-MM-DD") : "",

                // nse_seller_two:res.data.nse_seller_two,
                mode: "edit"
              })
              setIsNSE(res.data.seller_type !== 2 ? true : false)
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true)
              }
            }
          }
        }).catch((error) => {
          console.log(error);
        })
      getSecurityData();

    }
  })



  useEffect(() => {


    return () => {
      clearTimeout(timer.current);
    };

  }, []);

  const handleShow = (name, type,item) => {
    setEditItem(item)
    setTransactionType(`${name}`);
    setSeverity("success");
    setTitleModal( type ==="Add" ? "Add New Scrip" : "Edit Scrip");
    setSnackbarTitle(type ==="Add" ?  "New Scrip Added Successfully" : "Scrip updated Successfully");
    setOpenModal(true);
  };

  const handleShowClose = () => {
    setOpenModal(false);
    setInvoiceDialog(false)
    setOpenTax(false)
  };


  const handleShowTdsClose = () => {
    setOpenModalForTcsAndTds(false);
  };




  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        if (res) {

          if (res.status === 200) {


            let item = res.data.map((items) => {
              if (mode === "edit") {
                return {
                  code: items.user_code,
                  lable: `${items.user_name} ( ${items.user_pan ?items.user_pan.toUpperCase() : items.user_code.toUpperCase()} ) ${items.verified_status ===1 ? '✅' : '❌'}`,
                };
              } else {
                return {
                  // id: items.id,
                  code: items.user_code,
                  lable: `${items.user_name} ( ${items.user_pan ?items.user_pan.toUpperCase() : items.user_code.toUpperCase()} ) ${items.verified_status ===1 ? '✅' : '❌'}`,
                };
              }

            });

            setChannelPartner(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }

          }
        }
      })
      .catch((error) => {
        console.log("error");
      });


    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER,INVESTER_CLIENT")
      .then((res) => {

        if (res) {


          if (res.status === 200) {


            let item = res.data.map((items) => {
              if (mode === "edit") {
                return {
                  code: items.user_code,
                  id: items.id,
                  lable: `${items.user_name} ( ${items.user_pan ?items.user_pan.toUpperCase() : items.user_code.toUpperCase()} )  ${items.verified_status ===1 ? '✅' : '❌'}`,
                  type:items.code
                };
              } else {
                return {
                  id: items.id,
                  code: items.user_code,
                  lable: `${items.user_name} ( ${items.user_pan ?items.user_pan.toUpperCase() : items.user_code.toUpperCase()} )  ${items.verified_status ===1 ? '✅' : '❌'}`,
                  status:items.verified_status,
                  type:items.code
                };
              }
            });
            setInvestorOrVendor(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }


      })
      .catch((error) => {
        console.log("error");
      });

    getLookUpDataService
      .getLookUpData("service_order_status")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const item1 = res.data[0].service_order_status.map((items) => {
              return { code: items.code, label: items.description, };
            });
            setOrderStatus(item1)
            const orderStatus = item1.filter((item)=>{
              return  item.code !=="STAGE_ONE_PROCESSING" &&  item.code !=="STAGE_ONE_PROCESSED" &&  item.code !=="STAGE_TWO_PROCESSING" &&  item.code !=="STAGE_TWO_PROCESSED"
            })
            setServiceOrderStatus(orderStatus);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }

          }
        }

      })
      .catch((error) => {
        console.log(error);
      });

    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {


          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                code: items.user_code,
                lable: `${items.user_name}`,
              };
            });
            let seller = res.data.map((items) => {
              return items.user_name
            });
            setNseSellers(seller)
            setSalesAndOperation(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const servicetype = [
    { id: "Sell", label: "SELL" },
    { id: "Buy", label: "BUY" },
  ];
  const transactionTypes = [
    "DIRECT",
    "INTERMEDIATE"
  ];

const handleFutureOrdersTax =((value)=>{
  const formData = value.map((item)=>{
    return{
      id:item.id,
      tcsTdsValue:item.newTaxValue,
      is_tcs_tds_collected:item.is_tcs_tds_collected
    }
  })
  let sumConsolidatedPrice = securityTableData.reduce((count, item) => {
    return count + (item.is_nse_trade && item.is_tcs_tds_collected ? (Number(item.stampDutyFee) + Number(item.documentationCharges)) : 0) +  Number(item.consolidated_price);
  }, 0);
  serviceOrderService.updateFutureOrderTaxValue(formData).then((res)=>{
    if(res.data.success){
      setTdsTcsInitialValue({...tdsTcsInitialValue,taxType:value[0].is_tcs_tds_collected ===1 ? "TCS" : "TDS"})
      if(value[0].is_tcs_tds_collected ===1){
        setTcsValue(value[0].newTaxValue)
        setTdsValue(0)
        setFinalOrderPrice((parseFloat(sumConsolidatedPrice) + parseFloat(value[0].newTaxValue)).toFixed(3));     
      }else{
        setTdsValue(value[0].newTaxValue)
        setTcsValue(0)
        setFinalOrderPrice(Math.abs((parseFloat(sumConsolidatedPrice) - parseFloat(value[0].newTaxValue)).toFixed(3)));
      }
      setOpenTax(false)
      setSeverity("success");
      setSnackbarTitle("TDS/TCS Updated Successfully");
      setSnackBarOpen(true);
    }else{
      setSeverity("error");
      setSnackbarTitle("TDS/TCS Updation Failed");
      setSnackBarOpen(true);
    }
  })
})







  const submitHandle =  (values) => {
    const result = compareJSON(values,editItem)
    if(mode==='edit'){
      const data = {
        "security_id":values.id,
        "quantity":values.quantity,
        "price":values.price,
        "commission":values.commission,
        "procuredBy":values.procuredBy,
        "id":values.orderId,
        "facilitator_price":values.facilitatorPrice,
        "procurement_price":values.buyPrice ? values.buyPrice :0,
        "service_id":location?.state?.userID,
        "documentation_charges":values.documentationCharges ? values.documentationCharges : 0,
        "stamp_duty_fee":values.stampDutyCharges ? (((Number(values.quantity) * values.price)*0.015)/100).toFixed(3) : 0,
        "updatedValues":JSON.stringify(result.filter(item=> item.field ==="commission" ||item.field ==="price" || (item.field === "quantity" )|| item.field === 'security_name' || item.field === 'buyPrice' ))
    }
      serviceOrderService.updateIndividualSeviceOrder(data).then((res) => {
        if (res.status === 200) {
         
          // eslint-disable-next-line eqeqeq
          if(values.quantity ==editItem.quantity && values.price ==editItem.price){
            getSecurityData()
          }else{
          const orderType = serviceOrderType === "BUY" ? 1 : 2
          serviceOrderService.getFutureOrders(props?.id ? props.id : location.state.userID,userID,orderType).then((res)=>{
            if(res.data.success){
              const previousTotal =parseFloat(totalTransactionValue)
              const sum = res.data.data.reduce((accumulator, currentObject) => {
                const value = parseFloat(currentObject.sum);
                if (!isNaN(value)) {
                  return accumulator + value;
                } else {
                  return accumulator;
                }
              }, 0);
              const is_tcs_tds_added_for_future_orders = res.data.data.some((obj) => obj.is_tcs_tds_collected > 0);
              const crossed50L = (sum+previousTotal >5000000 ||is_tcs_tds_added_for_future_orders) ? true :false
              setTotalOrderValue(crossed50L)
              let isTcsCrossed =0
              const newArray = res.data.data.map((item,index) => {
                let sumCrossed50L = false;
                const previousSum = index > 0 ? res.data.data.slice(0, index).reduce((acc, obj) => acc + parseFloat(obj.sum), 0) : 0;
                const totalSum = previousSum + parseFloat(item.sum)+previousTotal;
                isTcsCrossed = totalSum > 5000000 ? 1 +isTcsCrossed : 0
                if (totalSum > 5000000 && !sumCrossed50L) {

                  sumCrossed50L = true; 
                  const taxValue =isTcsCrossed ===1 && previousTotal<5000000 ? (totalSum -5000000)/1000 : item.sum/1000
                  return { ...item,  
                  newTaxValue:taxValue, 
                  newTaxValue1:taxValue,  
                  is_tcs_tds_collected:item.is_tcs_tds_collected  ===0 ? 2  : item.is_tcs_tds_collected
                };
                } else {
                  return{ ...item,  
                  newTaxValue:0 ,  
                  is_tcs_tds_collected:totalSum<5000000 ? 0 :item.is_tcs_tds_collected
                }
                }
              });  
              setFutureOrders(newArray)
              setOpenTax(true)
            }
          })
          getSecurityData('tax')
          }
          setOpenModal(false);
          setSnackBarOpen(true);
        } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenModal(false);
                setOpenSessionExpiredModal(true)
                createNewOrder(values,'0')
            }
        }
      })
    }else{
      setEditItem(false)
      values.consolidated_price = (Number(values.quantity) * values.price).toFixed(3)
      values.stampDutyFee =values.stampDutyCharges ? (((Number(values.quantity) * values.price)*0.015)/100).toFixed(3) : 0
      values.buyPrice = values.buyPrice !== "" ? values.buyPrice : 0
      values.commission = Number( values.commission)
      values.price = Number( values.price)
      values.facilitatorPrice = Number( values.facilitatorPrice)
      let existingIndex = securityTableData.findIndex(item => item?.security_name === editItem?.security_name);
      if (existingIndex !== -1) {
        // If an existing object was found, update it
        securityTableData[existingIndex] = values;
      } else {
        // If no existing object was found, push the new item
        securityTableData.push(values);
      }
      setOpenModal(false);
      setSnackBarOpen(true);
      sumOfConsolidated();
    }
  }; 

  const submitTCSTDSHandle = (values) => {
    setTdsTcsInitialValue(values)
    updateTcsOrTdsValue(values)
    setOpenModalForTcsAndTds(false)
  };

  const handleDelete = (item) => {

    for (var i = securityTableData.length - 1; i >= 0; --i) {
      if (securityTableData[i].id === item.id) {
        securityTableData.splice(i, 1);
      }
      sumOfConsolidated();
      setSeverity("success");
      setSnackbarTitle("Scrip Deleted Successfully");
      setSnackBarOpen(true);
    }




  }


  const getVendorDematAccounts = async (userId,mode) => {
    return userService
      .getUserDemat(userId)
      .then(async (res) => {
        if (res) {

          if (res.status === 200) {
            let item = await res.data.map((items) => {

              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                  client_id:items.client_id,
                  dp_id:items.dp_id,
                  dp_with:items.dp_with
                };
              } else {

                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} )`,
                  client_id:items.client_id,
                  dp_id:items.dp_id,
                  dp_with:items.dp_with
                };
              }

            });

            let primaryDemat = await res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                  client_id:items.client_id,
                  dp_id:items.dp_id,
                  dp_with:items.dp_with
                };
              }
            }).filter((data) => {
              return data !== undefined
            })




            setVendorDematAccountList(item)
            return mode ?item : primaryDemat;

          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }




  const getVendorBankAccounts = async (userId,mode) => {

    return userService.getUserBankAccounts(userId)
      .then(async (res) => {
        if (res) {


          if (res.status === 200) {
            let item = await res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                  name:items.beneficiary_name,
                  bankName:items.bank_name,
                  accountNumber:items.account_no,
                  ifsc:items.ifsc_code,
                };
              } else {
                return {
                  id: items.id,
                  lable: `${items.bank_name} ( ${items.account_no} )`,
                  name:items.beneficiary_name,
                  bankName:items.bank_name,
                  accountNumber:items.account_no,
                  ifsc:items.ifsc_code,
                };
              }

            });


            let primaryBank = await res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                  name:items.beneficiary_name,
                  bankName:items.bank_name,
                  accountNumber:items.account_no,
                  ifsc:items.ifsc_code,
                };
              }
            }).filter((data) => {
              return data !== undefined
            })



            setVendorBankAccountList(item);

            return mode ? item  :  primaryBank

          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }


const getUlkAuthPersons=((id)=>{
  userService.getAuthPersonList(id).then((res)=>{
    if(res.status ===200){
      if(res.data.stack_holders){
        setUlkAuthPersons(JSON.parse(res.data.stack_holders))
      }else{
        setUlkAuthPersons([])
      }
    }
  })
})

const getNSEAuthPersons=((id)=>{
  userService.getAuthPersonList(id).then((res)=>{
    console.log(res)
    if(res.status ===200){
      if(res.data.stack_holders){
        setClientAuthPersons(JSON.parse(res.data.stack_holders))
      }else{
        setClientAuthPersons([])
      }
    }
  })
})



  const getSellerDematAccounts = (id, mode,user) => {
    return userService
      .getUserDemat(id)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                  dp_with:items.dp_with,
                  dp_id:items.dp_id,
                  client_id:items.client_id
                };
              } else {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} )`,
                  dp_with:items.dp_with,
                  dp_id:items.dp_id,
                  client_id:items.client_id
                };
              }


            });


            let primaryDemat = res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                  dp_with:items.dp_with,
                  dp_id:items.dp_id,
                  client_id:items.client_id
                };
              }
            }).filter((data) => {
              return data !== undefined
            })


            if (primaryDemat.length > 0) {
              serviceOrderBasicData.order_owner_demat_id = primaryDemat[0]
            } else {
              serviceOrderBasicData.order_owner_demat_id = ""
            }
            user === 'facilitator' ? setFacilitatorDematAccountList(item):  setSellerDematAccountList(item);
            return mode ? item  :  primaryDemat


          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSellerBankAccounts = (id, mode,user) => {
    return userService.getUserBankAccounts(id).then((res) => {
      if (res) {
        if (res.status === 200) {


          let item = res.data.map((items) => {
            if (items.is_primary === true) {
              return {
                id: items.id,
                lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                name:items.beneficiary_name,
                bankName:items.bank_name,
                accountNumber:items.account_no,
                ifsc:items.ifsc_code,
              };
            } else {
              return {
                id: items.id,
                lable: `${items.bank_name} ( ${items.account_no} )`,
                name:items.beneficiary_name,
                bankName:items.bank_name,
                accountNumber:items.account_no,
                ifsc:items.ifsc_code,
              };
            }
          });

          // eslint-disable-next-line array-callback-return
          let primaryBank = res.data.map((items) => {
            if (items.is_primary === true) {
              return {
                id: items.id,
                lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                name:items.beneficiary_name,
                bankName:items.bank_name,
                accountNumber:items.account_no,
                ifsc:items.ifsc_code,
              };
            }
          }).filter((data) => {
            return data !== undefined
          })



          if (primaryBank.length > 0) {
            serviceOrderBasicData.order_owner_bank_id = primaryBank[0]
          } else {
            serviceOrderBasicData.order_owner_bank_id = ""
          }

          user === 'facilitator' ? setFacilitatorBankAccountList(item): setSellerBankAccountList(item);
          return mode ? item  :  primaryBank

        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    });
  };

  const totalCommissionSellPrice = securityTableData.map((item) => {
    return Number(((item.price - item.commission).toFixed(2) * item.quantity).toFixed(2))
  })
  const totalCommissionBuyPrice = securityTableData.map((item) => {
    return Number(((item.commission - item.price).toFixed(2) * item.quantity).toFixed(2))
  })

  const totalFacilitatorSellPrice = securityTableData.map((item) => {
    return Number(((item.price - item.facilitatorPrice).toFixed(2) * item.quantity).toFixed(2))
  })
  const totalFacilitatorBuyPrice = securityTableData.map((item) => {
    return Number(((item.facilitatorPrice - item.price).toFixed(2) * item.quantity).toFixed(2))
  })


  const totalCommissionPrice = ((type) => {
    return type === "BUY" ? totalCommissionBuyPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(3) : totalCommissionSellPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(3)
  })
  const totalFacilitatorCommissionPrice = ((type) => {
    return type === "BUY" ? totalFacilitatorBuyPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(3) : totalFacilitatorSellPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(3)
  })
  const [taxType,setTaxType] =useState(false)


  useEffect(() => {
  if(mode ===null){
    getSellerDematAccounts(DefaultUnlistedAccount[1].id);
     getSellerBankAccounts(DefaultUnlistedAccount[1].id);
     getUlkAuthPersons(DefaultUnlistedAccount[1].id);
  }
  }, [mode])

  useEffect(() => {
    if(taxType){
     setTdsTcsInitialValue({...tdsTcsInitialValue,taxType:taxType})
    }
    }, [taxType])
  //totalOrderValue.data.message[0].totalvalue ->tcs not paid
  //totalOrderValue.data.message[0].total_trade -> tcs paid
  async function getInvestorsTotalSellOrBuy(investorId, order_type,orderId) {
    let totalOrderValue = await userService.getInvestorsTotalSellOrBuy(investorId, order_type,orderId)
    if (totalOrderValue?.data.message.length > 0) {
      setTaxType(totalOrderValue?.data.message[0].is_tcs_tds_collected===2 ? "TDS" : totalOrderValue?.data.message[0].is_tcs_tds_collected===1 ? "TCS": false)
      // setTotalPreviousOrderSum(totalOrderValue.data.message[0].totalvalue == null ? 0 : totalOrderValue.data.message[0].totalvalue)
      setTotalTcsOrTdsPaidValue(totalOrderValue.data.message[0].total_trade == null ? 0 : totalOrderValue.data.message[0].total_trade)
      setTotalTransactionValue(totalOrderValue.data.message[0].total_trade == null ? 0 : totalOrderValue.data.message[0].total_trade )
    }


  }



  let totalConsolidatedPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(consolidatedPrice);

  const buyTotalPrice = ((type) => {
    return (
      new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 3
      }).format(sum(consolidatedPrice, totalCommissionPrice(type)))
    )
  })


  let totalPreviousOrderSumFormated = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(totalTransactionValue);

  // let userTotalTrade = new Intl.NumberFormat("en-IN", {
  //   style: "currency",
  //   currency: "INR",
  // }).format(totalPreviousOrderSum);

  let tcsTdsApplicableSumFormated = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(tcsTdsApplicableSum);

  let tcsValueFormatted = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(tcsValue);

  let tdsValueFormated = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(tdsValue);

  let totalOrderValueFormated = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 3
  }).format(finalOrderPrice);






  //tcsValue =1
  //tdsValue = 2
  //no tcsValue and tds = 0

  const createServiceOrder = async(values) => {
    const formData = new FormData();
    formData.append('file', values.dematImage)
    if(values.dematImage){
      await serviceOrderService.uploadBrandingImage(formData).then((res) => {
        if (res.status === 201) {
          createNewOrder(values,res.data.url)
        } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenModal(false);
                setOpenSessionExpiredModal(true)
                createNewOrder(values,'0')
            }
        }
    })
  }else{
    createNewOrder(values,'0')
  }
  };

  const createNewOrder =((values,url)=>{
    const array =values.channel_partners.map((item)=>{
      return item.code
    })
    values.security = securityData;
    values.commission_data = values.channel_partner_report.map((item)=>{
      return{
        ...item,commission_value:Number(item.commission_value)
      }
    })
    values.has_commission_split = values.transaction_type === "INTERMEDIATE" ? true: false
    values.additional_channel_partner = array
    values.custom_branding_logo = url
    values.is_custom_branding = url ==='0' ?false : true
    values.channel_partner =values.transaction_type === "INTERMEDIATE"? values.channel_partners[0].code : ""
    values.co_managed_one = values.co_managed_one.code ? values.co_managed_one.code : ""
    values.co_managed_two = values.co_managed_two.code ? values.co_managed_two.code : ""
    values.status =isHiddenScripExist.length>0 ? "ONHOLD": values.status.code
    values.order_owner = values.order_owner.code
    values.lead_owner = values.lead_owner.code
    values.authorized_person = values.authorizedPerson
    values.spa_date =  values.spa_date ? values.spa_date : null
    values.stage1_document_date = values.stage1_document_date ? values.stage1_document_date : null
    values.assign = values.assign.code
    values.demat_id = values.demat_id.id
    values.bank_id = values.bank_id.id
    values.order_owner_demat_id = values.order_owner_demat_id.id
    values.order_owner_bank_id = values.order_owner_bank_id.id
    values.facilitator_demat_id =values.seller_type ==='Others'? values.facilitator_demat_id.id : null
    values.facilitator_bank_id = values.seller_type ==='Others'? values.facilitator_bank_id.id :null
    values.facilitator = values.seller_type ==='Others'? values.facilitator.code :""
    values.seller_type=values.seller_type ==='Others' ? 2 :1
    values.referral = values.referral.code
    values.payment_date = values.payment_date ? values.payment_date : null
    values.payment_mode = values.payment_mode ? parseInt(values.payment_mode) : 0
    values.commission_paid = values.commissionStatus
    values.commission_paid_date = values.commissionStatus ? values.commission_paid_date : null
    values.referral_paid_date = values.referral_paid_status ? values.referral_paid_date : null
    values.transaction_date = values.transaction_date ? values.transaction_date : null
    values.is_tcs_tds_collected_value = tcsValue === 0 && tdsValue === 0 ? 0 : tcsValue > 0 ? tcsValue : tdsValue
    values.is_tcs_tds_collected = tcsValue === 0 && tdsValue === 0 ? 0 : tcsValue > 0 ? 1 : 2
    values.facilitating_type = values.facilitating_type ? values.facilitating_type: 0
    values.referral_amount = (values.referral_amount === null ? null : parseFloat(Number(values.referral_amount.toFixed(3))))
    values.need_approval =isHiddenScripExist.length>0 ? true: false
    values.hidden_scrips = isHiddenScripExist
    if (values.order_type === "SELL") {
      values.investor = values.investorOrVendor.code;
      delete values.investorOrVendor;
    } else {
      values.vendor = values.investorOrVendor.code;
      delete values.investorOrVendor;
    }
    serviceOrderService
      .postServiceOrder(values)
      .then((res) => {
        timer.current = window.setTimeout(() => {
          setLoading(false);
        }, 3000);
        if (res) {


          if (res.status === 201) {
            setSeverity("success");
            setSnackbarTitle("Order created Successfully");
            setSnackBarOpen(true);
            handleShowClose()   
            setTimeout(
              () => navigate(`/app/service-order-details/${res.data.id}`, { replace: true }),
              1000
            );
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            } else {
              setSeverity("error");
              setSnackbarTitle("Oops Something went wrong");
              setSnackBarOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setSeverity("error");
        setSnackbarTitle("Oops Something went wrong");
        setSnackBarOpen(true);
      });
  })

  const sum = ((a, b) => {
    return Number(a) + Number(b)
  })

  const updateServiceOrder = (values,tax) => {
    // values.id = Number(props?.id ? props.id  : location.state?.userID)
    const array =values.channel_partners?.map((item)=>{
      return item.code
    })
    // values.additional_channel_partner = array
    // if (values.transaction_type === "DIRECT") {
    //   values.channel_partner = ""
    // } else {
    //   values.channel_partner = values.channel_partners[0].code
    // }

    const result = compareJSON(values,serviceOrderBasicData)
    // values.updatedValues= JSON.stringify(result)
    // values.commission_data = values.channel_partner_report
    // values.security = securityData
    // values.has_commission_split = values.transaction_type === "INTERMEDIATE" ? true: false
    // values.payment_mode = values.payment_mode ? parseInt(values.payment_mode) : 0
    // values.payment_date = values.payment_date ? values.payment_date : null
    // values.transaction_date = values.transaction_date ? values.transaction_date : null
    // values.status = values.status.code
    // values.lead_owner = values.lead_owner.code
    // values.co_managed_one = values.co_managed_one.code
    // values.co_managed_two = values.co_managed_two.code 
    // values.assign = values.assign.code
    // values.referral = (values.referral.code === undefined || "" ? null : values.referral.code)
    // values.referral_amount = (values.referral_amount === "" || null ? null : parseFloat(Number(values.referral_amount?.toFixed(3))))
    // // values.is_tcs_tds_collected = 
    // values.facilitating_type = values.facilitating_type ? values.facilitating_type: 0
    // values.commission_paid = values.commissionStatus
    // values.commission_paid_date =values.commissionStatus ?  values.commission_paid_date : null
    // values.is_tcs_tds_collected = showTcsOrTds ? tcsValue === 0 && tdsValue === 0 ? 0 : tcsValue > 0 ? 1 : 2 :0
    // values.demat_id = values.demat_id.id
    // values.bank_id = values.bank_id.id
    // values.seller_type=values.seller_type ==='Others' ? 2 :1
    // values.order_owner_demat_id = values.order_owner_demat_id.id
    // values.order_owner_bank_id = values.order_owner_bank_id.id
    // values.facilitator_demat_id = values.facilitator_demat_id?.id || null
    // values.facilitator_bank_id =  values.facilitator_bank_id?.id || null
    // values.facilitator =  values.facilitator?.code || null
    // values.order_owner = values.order_owner.code
    // if (values.order_type === "SELL") {
    //   values.investor = values.investorOrVendor.code;
    //   delete values.investorOrVendor;
    // } else {
    //   values.vendor = values.investorOrVendor.code;
    //   delete values.investorOrVendor;
    // }
    const data={
      "disclose_client_details": values.disclose_client_details,
      "facilitating_type": values.facilitating_type ? values.facilitating_type: 0,
      "transaction_type": values.transaction_type,
      "commission_split_type": values.commission_split_type,
      "channel_partner_report": values.channel_partner_report,
      "order_type": values.order_type,
      "order_owner": values.order_owner.code,
      "status": values.status.code,
      "lead_owner": values.lead_owner.code,
      "co_managed_one": values.co_managed_one.code,
      "co_managed_two": values.co_managed_two.code,
      "assign": values.assign.code,
      "demat_id": values.demat_id.id,
      "bank_id": values.bank_id.id,
      "payment_date": values.payment_date ? values.payment_date : null,
      "transaction_date": values.transaction_date ? values.transaction_date : null,
      "payment_mode": values.payment_mode ? parseInt(values.payment_mode) : 0,
      "order_owner_demat_id": values.order_owner_demat_id.id,
      "order_owner_bank_id": values.order_owner_bank_id.id,
      "commissionStatus": values.commissionStatus,
      "commission_paid_date": values.commissionStatus ?  values.commission_paid_date : null,
      "referral":  (values.referral.code === undefined || "" ? null : values.referral.code),
      "referral_amount": (values.referral_amount === "" || null ? null : parseFloat(Number(values.referral_amount?.toFixed(3)))),
      "is_purchased_online": values.is_purchased_online,
      "remarks": values.remarks,
      "is_nse_trade": values.is_nse_trade,
      "spa_type": values.spa_type,
      "nse_seller_one": values.nse_seller_one,
      "seller_type": values.seller_type ==='Others' ? 2 :1,
      "facilitator": values.facilitator?.code || null,
      "facilitator_bank_id": values.facilitator_bank_id?.id || null,
      "facilitator_demat_id": values.facilitator_demat_id?.id || null,
      "mode": values.mode,
      "is_tcs_tds_collected_value": values.is_tcs_tds_collected_value,
      "id": Number(props?.id ? props.id  : location.state?.userID),
      "additional_channel_partner": array,
      "channel_partner": values.transaction_type === "DIRECT" ? "" :values.channel_partners[0].code,
      "updatedValues":JSON.stringify(result) ,
      "commission_data": values.channel_partner_report,
      "security": securityData,
      "has_commission_split": values.transaction_type === "INTERMEDIATE" ? true: false,
      "commission_paid": values.commissionStatus,
      "is_tcs_tds_collected": showTcsOrTds ? tcsValue === 0 && tdsValue === 0 ? 0 : tcsValue > 0 ? 1 : 2 :0,
      "investor": values.investorOrVendor.code,
      "vendor": values.investorOrVendor.code,
      "referral_paid_date":values.referral_paid_status ? values.referral_paid_date : null,
      "referral_paid_status":values.referral_paid_status,
      "authorized_person":values.authorizedPerson,
      "ulk_authorized_person":values.ulk_authorized_person,
      "spa_date":values.spa_date ? values.spa_date : null,
      "stage1_document_date":values.stage1_document_date ? values.stage1_document_date : null,
  }
    serviceOrderService.putServiceOrderData(data)
      .then((res) => {
        timer.current = window.setTimeout(() => {
          setLoading(false);
        }, 3000);
        if (res) {

          if (res.status === 200) {
            if(tax){
              // getSecurityData()
            }else{
              setSeverity("success");
              setSnackbarTitle("Order Updated Successfully");
              setSnackBarOpen(true);
              setTimeout(
                () => navigate(`/app/service-order-details/${res.data.id}`, { replace: true }),
                1000
              );
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            } else {
              setSeverity("error");
              setSnackbarTitle("Oops Something went wrong");
              setSnackBarOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setSeverity("error");
        setSnackbarTitle("Oops Something went wrong");
        setSnackBarOpen(true);

      });
  }

  const handleNSEOrderStatus =((value)=>{
    if(value){
      const nseStatus = orderStatus.filter((item)=>{
       return  item.code !=="ONHOLD" && item.code !=="PREPROCESSING" &&  item.code !=="CANCELLED"
       })
       setServiceOrderStatus(nseStatus)
    }else{
      const status = orderStatus.filter((item)=>{
        return  item.code !=="STAGE_ONE_PROCESSING" &&  item.code !=="STAGE_ONE_PROCESSED" &&  item.code !=="STAGE_TWO_PROCESSING" &&  item.code !=="STAGE_TWO_PROCESSED"
        })
        setServiceOrderStatus(status) 
    }
  })

  const renderTooltipTitle =((item)=>{
    return(
      <Box style={{  margin: 0, padding: "10px" }}>
      <Box className={classes.table1}>
      <table>
      <tr>
      <td  style={{fontWeight:500}}>Name</td>
            <td  style={{fontWeight:500, margin:'1rem'}}>Qty</td>
            <td  style={{fontWeight:500}}>Price</td>
      </tr>
      {item.procuredBy?.map((item)=>{
          return(
            <tr key={item.name.lable}>
            <td>{item.name.lable}</td>
            <td style={{ marginLeft:'1rem'}}>{item.value}</td>
            <td>{item.price}</td>
          </tr>
          )
        })}
      </table>
      </Box>
  
  </Box>
    )
  })

  const filteredOptions = ((options,values)=>{
    return values? options.filter(option =>
      option.toLowerCase().includes(values?.toLowerCase())
    ) : options
  })

  useEffect(()=>{
    if(formikValues){
      let array = formikValues.channel_partner_report.map((values)=>{
        const Commission =  (parseFloat(totalCommissionPrice(formikValues.order_type)).toFixed(3))
          return(
            {
              ...values,
              commission_value:parseFloat((values.commission_percentage*Commission)/100).toFixed(2)
            }
          )
        })
        const newValue={
          ...serviceOrderBasicData,
          channel_partners:formikValues.channel_partners,
          channel_partner_report:array
        }
        setServiceOrderBasicData(newValue)
        if(mode ==='edit' && newValue.transaction_type === 'INTERMEDIATE'){
          updateServiceOrder(newValue,'tax')
        }
    }
  },[securityTableData])
  return (
    <Root>
      <Page title="Unlistedkart | User Create">
      {props?.editType ? null:<Button
        sx={{marginBottom:'1rem',
          borderRadius: "14px 14px 0 0",
          boxShadow: "none",
          textTransform: "capitalize",
          fontSize: "0.9rem",
          lineHeight: "1.3",      
        }}
          onClick={()=>{
            navigate(`/app/service-order-details/${location?.state?.userID}`, {state:location.state})
          }}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          disabled={!userID}
          className={classes.addButton}
          name="addDemat"
          value="addDemat"
        >
          Back
        </Button>}
        <Formik
          enableReinitialize={true}
          initialValues={serviceOrderBasicData}
          validateOnBlur={false}

          validationSchema={Yup.object().shape({
            transaction_type: Yup.string().required("Required"),
            order_type: Yup.string().required("Required"),
            bank_id:  Yup.string().required("Required"),
            demat_id:  Yup.string().required("Required"),
            channel_partners: Yup.string().when("transaction_type", {
              is: "INTERMEDIATE",
              then: Yup.string().required("Required"),
              otherwise: Yup.string(),
            }),
            channel_partner_report: Yup.array(
              Yup.object({
                commission_paid_date: Yup.string().when("commission_paid_status", {
                  is: true,
                  then: Yup.string().required("Commission date required"),
                  otherwise: Yup.string().nullable(),
                }),
                  commission_value: Yup.number()
                  .required('Commission value required'),
                  commission_percentage: Yup.number()
                  .required('Commission percentage required'),
              })
            ),
            commissionStatus: Yup.string().nullable(),
            commission_paid_date: Yup.string().when("commissionStatus", {
              is: "true",
              then: Yup.string().required("Commsission date is required"),
              otherwise: Yup.string(),
            }),
            order_owner_bank_id:  Yup.string().required("Required"),
            order_owner_demat_id: Yup.string().required("Required"),
            order_owner: Yup.string().when("mode", {
              is: null,
              then: Yup.string().required("Required"),
              otherwise: Yup.string(),
            }),
            facilitator: Yup.string().when("seller_type", {
              is: "Others",
              then: Yup.string().required("Required"),
              otherwise: Yup.string(),
            }),
            investorOrVendor: Yup.object().required("Required"),
            status: Yup.object().required("Required"),
            lead_owner: Yup.string().required("Required"),
            referral: Yup.string(),
            co_managed_one: Yup.string(),
            co_managed_two: Yup.string(),
            payment_date: Yup.string().when(["is_purchased_online", "status"], {
              is: (is_purchased_online, status) => {
                return (
                  is_purchased_online || status?.code === 'CANCELLED' || status?.code === 'ONHOLD' // Key-value pair exists in Field 1
                );
              },
              then: Yup.string().nullable(),
              otherwise: Yup.string().required("Required"),
            }),
            authorizedPerson: Yup.string().when(["investorOrVendor", "is_nse_trade"], {
              is: (investorOrVendor, is_nse_trade) => {
                return ( investorOrVendor?.type === "COMPANY" && is_nse_trade // Key-value pair exists in Field 1
                );
              },
              then: Yup.string().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            nse_seller_one: Yup.string().when("is_nse_trade", {
              is: true,
              then: Yup.string().required("Required"),
            }),
            ulk_authorized_person: Yup.string().when("is_nse_trade", {
              is: true,
              then: Yup.string().required("Required"),
              otherwise:Yup.string().nullable()
            }),
            spa_date: Yup.string().when("is_nse_trade", {
              is: true,
              then: Yup.string().required("Required"),
              otherwise:Yup.string().nullable()
            }),
            // nse_seller_two: Yup.string().when("spa_type", {
            //   is: '2',
            //   then: Yup.string().required("Required"),
            //   otherwise: Yup.string(),
            // }),
            payment_mode: Yup.number().when(["is_purchased_online", "status"], {
              is: (is_purchased_online, status) => {
                return (
                  is_purchased_online || status.code === 'CANCELLED' || status.code === 'ONHOLD' // Key-value pair exists in Field 1
                );
              },
              then: Yup.number().nullable(),
              otherwise: Yup.number().required("Required"),
            }),
            transaction_date: Yup.string().when(["is_purchased_online", "status"], {
              is: (is_purchased_online, status) => {
                return (
                  is_purchased_online || status?.code === 'CANCELLED' || status?.code === 'ONHOLD' // Key-value pair exists in Field 1
                );
              },
              then: Yup.string().nullable(),
              otherwise: Yup.string().required("Required"),
            }),
            referral_paid_date: Yup.string().when("referral_paid_status", {
              is: true,
              then: Yup.string().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            facilitator_bank_id: Yup.string().when(["disclose_client_details", "seller_type"], {
              is: (disclose_client_details, seller_type) => {
                return (
                  disclose_client_details ===false  && seller_type === 'Others' // Key-value pair exists in Field 1
                );
              },
              then: Yup.string().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            facilitator_demat_id: Yup.string().when(["disclose_client_details", "seller_type"], {
              is: (disclose_client_details, seller_type) => {
                return (
                  disclose_client_details ===false && seller_type === 'Others' // Key-value pair exists in Field 1
                );
              },
              then: Yup.string().required("Required"),
              otherwise: Yup.string().nullable(),
            }),
            referral_amount: Yup.number().positive().nullable()
              .when('referral', {
                is: (referral) => referral && referral.length > 0,
                then: Yup.number().nullable()
                  .required('The field is required')
              }),
            assign: Yup.string().required("Required"),

          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false)
            if (mode === null) {
              setInvoiceDialog(true)
            } else {
              setLoading(true);
              values.is_tcs_tds_collected_value =  showTcsOrTds ? tcsValue === 0 && tdsValue === 0 ? 0 : tcsValue > 0 ? tcsValue : tdsValue :0
              updateServiceOrder(values)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            setFieldValue,
            values,

          }) => (
            <form onSubmit={handleSubmit}>
                <FieldArray
              name="channel_partner_report">
                      {({ push,remove}) => (
                  <div>
              <NormalCard title={mode === "edit" ? "Edit Order" : "Create Order"}>
                <div style={{ padding: theme.spacing(2) }}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl component="fieldset" color="primary">
                        <FormLabel component="legend" color="primary">
                          Type *
                        </FormLabel>
                        <RadioGroup
                          name="order_type"
                          color="primary"
                          value={values.order_type}

                          // error={Boolean(
                          //   touched.order_type && errors.order_type
                          // )}
                          // helperText={touched.order_type && errors.order_type}
                          onChange={async (event) => {
                            setSecurityTableData([])
                            setShowTcsOrTds(false)
                            const value =event.target.value
                            setFieldValue("order_type", value);
                            setServiceOrderType(value);
                            // if(values.id!=undefined){
                            //   setTotalPreviousOrderSum(0)
                            //   setTotalTcsOrTdsPaidValue(0)
                            //   getInvestorsTotalSellOrBuy(values.id,values.order_type)
                            // }
                            if(value ==="SELL"){
                              setFieldValue('facilitating_type','1')
                            }else{
                              setFieldValue('facilitating_type','2')
                            }
                            if (values.investorOrVendor) {
                              const primaryVendorBank = await getVendorBankAccounts(values.investorOrVendor?.id);
                              if (primaryVendorBank.length > 0) {
                                setFieldValue("bank_id", primaryVendorBank[0]);
                              }
                            }
                            values.investorOrVendor && getInvestorsTotalSellOrBuy(values.investorOrVendor?.id, value,0)

                          }}
                        // inputProps={{ autoFocus: true }}
                        >
                          <div>
                            {servicetype.map((item, i) => {
                              return (
                                <FormControlLabel
                                  key={i}
                                  value={item.label}
                                  disabled={mode === "edit"}
                                  color="primary"
                                  control={<Radio color="primary" />}
                                  label={item.label}
                                />
                              );
                            })}
                          </div>
                        </RadioGroup>
                        <FormHelperText style={{ color: "#F44336" }}>
                          {errors.order_type &&
                            touched.order_type &&
                            errors.order_type}
                        </FormHelperText>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
                    {values.order_type !== "" ? (        
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Autocomplete
                          id="transaction_type"
                          // name="transactionType"
                          options={transactionTypes}
                          // isOptionEqualToValue={(option, value) => option === value}
                          disabled={mode === "edit"}
                          value={values.transaction_type}
                          getOptionLabel={(option) => option || ""}
                          onChange={(e, value) => {
                            if (value !== null) {
                              // setSecurityTableData([])
                              setFieldValue("transaction_type", value);
                              if(value === 'DIRECT'){
                              setFieldValue("channel_partners", []);
                              setFieldValue("channel_partner_report", []);
                              }
                            } else {
                              setFieldValue("transaction_type", "");
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.transaction_type &&
                                errors.transaction_type
                              )}
                              helperText={
                                touched.transaction_type &&
                                errors.transaction_type
                              }
                              // inputProps={values.transactionType===""?{ autoFocus: true }:{autoFocus:false}}
                              name="transaction_type"
                              label="Transaction Type *"
                              fullWidth
                              variant="standard"
                            />
                          )}
                        />
                        {/* <span>{values.transactionType}</span> */}

                        <ErrorFocus />
                      </Grid> 
                    ) : (
                      <div></div>
                    )}
                    {values.transaction_type === "INTERMEDIATE" ? (
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Autocomplete
                          id="channel_partners"
                          // name="channelPartner"
                          multiple
                          value={values.channel_partners}
                          // options={channelPartner}
                          options={channelPartner?.filter(function (data) {
                               return (
                                  values.channel_partners?.filter(function (item) {
                                return item.code === data.code;
                              }).length === 0
                            );
                           
                          })}
                          // isOptionEqualToValue={(option, value) => option.code === value.code}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={index}
                                label={option.lable}
                                {...getTagProps({ index })}
                                onDelete={() => {
                                const commissionReportArray = values.channel_partner_report.filter((chip, i) => chip.channel_partner_id !== option.code)
                                const commissionArray = values.channel_partners.filter((chip, i) => i !== index)
                                setFieldValue('channel_partner_report',commissionReportArray)
                                setFieldValue('channel_partners',commissionArray)
                                }
                                }

                              />
                            ))
                          }
                                              getOptionLabel={(option) => option.lable || ""}
                          onChange={(e, value) => {
                            if (value !== null && value.length !==0) {
                              setFieldValue("channel_partners", value);
                                  push({
                                    commission_paid_status:false,
                                    commission_paid_date:'',
                                    commission_percentage:'',
                                    commission_value:'',
                                    name:value[value.length-1].lable,
                                    channel_partner_id:value[value.length-1].code
                                  })
                            } else if(value.length ===0){ 
                              setFieldValue('channel_partner_report',[])
                              setFieldValue('channel_partners',[])
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(
                                touched.channel_partners &&
                                errors.channel_partners
                              )}
                              helperText={
                                touched.channel_partners &&
                                errors.channel_partners
                              }
                              name="channel_partners"
                              label="Channel Partner *"
                              fullWidth
                              variant="standard"
                            />
                          )}
                        />
                        <ErrorFocus />
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </div>
                {values.transaction_type === "" && mode ==="edit" ?
                <div style={{ paddingBottom: theme.spacing(2) , textAlign:'center'}}>
                   Loading...
                </div>
                : null }
                {values.order_type !== "" ? (
                  <>
                    <div >
                   
                    <Grid container spacing={5} 
                    style={{ padding: theme.spacing(2) }} 
                    alignItems="center">
                        <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                      <FormControl  component="fieldset" variant="standard">
                          <FormLabel component="legend" >NSE Order</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.is_nse_trade}
                                name="is_nse_trade"
                                id="commissionStatus"
                                // disabled={mode === "edit"}
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={(e,value)=>{
                                  handleNSEOrderStatus(value)
                                  setFieldValue('status',{
                                    code: "ACTIVE",
                                    label: "Active",
                                  }) 
                                  setFieldValue('spa_type',"1")                            
                                  setFieldValue('is_nse_trade',value)  
                                  if(!value){
                                  setFieldValue('spa_type','0')                            
                                  }                          
                                }}
                                color="primary"
                              />
                              }
                              label={<Typography>Is NSE Order</Typography>}
                            />
                            </FormGroup>
                        </FormControl>
                        </Grid>
                        {
                     values.is_nse_trade ? <>
                       <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                        <FormControl component="fieldset" error={Boolean(touched.spa_type && errors.spa_type)}>
                          <FormLabel component="legend">SPA Type</FormLabel>
                          <RadioGroup row aria-label="payment_mode" name="spa_type"
                            value={values.spa_type}
                            id="payment_mode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Single" />
                            <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Double" />
                          </RadioGroup>
                          <FormHelperText>{touched.spa_type && errors.spa_type}</FormHelperText>
                        </FormControl>
                        <ErrorFocus />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete    
                              value={values.nse_seller_one}
                              onChange={(event, newValue) => {
                                setFieldValue('nse_seller_one',newValue);
                              }}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                              options={filteredOptions(nseSellers,values.nse_seller_one)}
                              freeSolo
                              onInputChange={(e)=>{
                                setFieldValue('nse_seller_one', e ?  e.target.value : values.nse_seller_one);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} 
                                variant="standard"
                                error={Boolean(touched.nse_seller_one && errors.nse_seller_one)}
                                helperText={touched.nse_seller_one && errors.nse_seller_one}
                                name="nse_seller_one"
                                label={'NSE Seller'} />
                              )}
                            />
                        </Grid>
                        {/* {
                          values.spa_type ==='2' &&
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Autocomplete    
                              value={values.nse_seller_two}
                              onChange={(event, newValue) => {
                                setFieldValue('nse_seller_two',newValue);
                              }}
                              options={filteredOptions(nseSellers,values.nse_seller_two)}
                              freeSolo
                              onInputChange={(e)=>{
                                setFieldValue('nse_seller_two', e ?  e.target.value : values.nse_seller_two);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} 
                                error={Boolean(touched.nse_seller_two && errors.nse_seller_two)}
                                helperText={touched.nse_seller_two && errors.nse_seller_two}
                                name="nseSellertTwo"
                                label={'NSE Seller Two'} />
                              )}
                            />
                        </Grid>
                        } */}
                      </>:null
                        }
                     
                      </Grid>
                        </div>
                    <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="status"
                            // name="status"
                            options={serviceOrderStatus}
                            getOptionLabel={(option) => option.label || ""}
                            value={values.status}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            disableClearable
                            onChange={(e, value) => {
                              if (value !== null) {
                                setFieldValue("status", value);
                              } else {
                                setFieldValue("status", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(touched.status && errors.status)}
                                helperText={touched.status && errors.status}
                                name="status"
                                label="Status *"
                                fullWidth
                                value={values.status}
                                variant="standard"
                              />
                            )}
                          />
                          <ErrorFocus />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="lead-Owner"
                            // name="leadOwner"
                            value={values.lead_owner}
                            options={salesAndOperation}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionLabel={(option) => option.lable || ""}
                            onChange={(e, value) => {
                              if (value !== null) {
                                setFieldValue("lead_owner", value);
                              } else {
                                setFieldValue("lead_owner", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched.lead_owner && errors.lead_owner
                                )}
                                helperText={
                                  touched.lead_owner && errors.lead_owner
                                }
                                name="lead_owner"
                                label="Lead Owner *"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                          <ErrorFocus />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="co-Managed1"
                            // name="leadOwner"
                            value={values.co_managed_one}
                            options={salesAndOperation}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionLabel={(option) => option.lable || ""}
                            onChange={(e, value) => {
                              if (value !== null) {
                                setFieldValue("co_managed_one", value);
                              } else {
                                setFieldValue("co_managed_one", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="co_managed_one"
                                label="Co Managed 1"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="co-Managed2"
                            // name="leadOwner"
                            value={values.co_managed_two}
                            options={salesAndOperation}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionLabel={(option) => option.lable || ""}
                            onChange={(e, value) => {
                              if (value !== null) {
                                setFieldValue("co_managed_two", value);
                              } else {
                                setFieldValue("co_managed_two", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="co_managed_two"
                                label="Co Managed 2"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="assign"
                            value={values.assign}
                            options={salesAndOperation}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionLabel={(option) => option.lable || ""}
                            onChange={(e, value) => {
                              if (value !== null) {
                                setFieldValue("assign", value);
                              } else {
                                setFieldValue("assign", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(touched.assign && errors.assign)}
                                helperText={touched.assign && errors.assign}
                                name="assign"
                                label="Assign *"
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <ErrorFocus />
                      </Grid>
                    </div>

                    <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="investor-Or-Vendor"
                            // name="investorOrVendor"
                            value={values.investorOrVendor}
                            options={investorOrVendor}
                            disabled={mode === "edit"}
                            // isOptionEqualToValue={(option, value) => option.code === value.code}
                            getOptionDisabled={(option) =>

                              option.lable === values.order_owner.lable

                            }
                            getOptionLabel={(option) => option.lable || ""}
                            onChange={async (e, value) => {
                              if (value !== null) {
                                setFieldValue("investorOrVendor", value);
                                setFieldValue("demat_id", "");
                                setFieldValue("bank_id", "");
                                setFieldValue("authorizedPerson", "");
                                setSecurityTableData([])
                                if (mode === null) {
                                  // const RMDetails = await getRMDetails(value.id);
                                  // if (RMDetails?.length > 0) {
                                  //   setFieldValue("co_managed_one",{
                                  //     code: RMDetails[0].user_code,
                                  //     lable: RMDetails[0].user_name,
                                  //   });
                                  // }
                                  getNSEAuthPersons(value.id)
                                  const primaryVendorDemat = await getVendorDematAccounts(value.id)
                                  if (primaryVendorDemat.length > 0) {
                                    setFieldValue("demat_id", primaryVendorDemat[0]);
                                  }
                                  const primaryVendorBank = await getVendorBankAccounts(value.id);
                                  if (primaryVendorBank.length > 0) {
                                    setFieldValue("bank_id", primaryVendorBank[0]);
                                  }
                                  getInvestorsTotalSellOrBuy(value.id, values.order_type,0)
                                }
                                setVerifyError(value.status ===2 ? true :false)
                              } else {
                                setFieldValue("investorOrVendor", "");
                                setFieldValue("demat_id", "");
                                setFieldValue("bank_id", "");
                                setFieldValue("authorizedPerson", "");
                                setVerifyError(false)
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                 ( touched.investorOrVendor &&
                                  errors.investorOrVendor) ||verifyError
                                )}
                                helperText={
                                  (touched.investorOrVendor &&
                                  errors.investorOrVendor)|| ( verifyError &&
                                    <Typography sx={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
                                      if(role ==='ADMIN' ||role ==='OPERATION'){
                                        navigate(`/app/user-details/${values.investorOrVendor.id}`) 
                                      }
                                    }}>User not verified for trading</Typography>
                                    )
                                }
                                value={values.investorOrVendor}
                                name="investorOrVendor"
                                label={values.order_type === "SELL" ? "Investor *" : "Vendor *"}
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                          <ErrorFocus />
                        </Grid>
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="demat_id"
                              // name="investorOrVendor"
                              value={values.demat_id}
                              options={vendorDematAccountList}
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("demat_id", value);
                                } else {
                                  setFieldValue("demat_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.demat_id && errors.demat_id
                                  )}
                                  helperText={touched.demat_id && errors.demat_id}
                                  value={values.demat_id}
                                  name="demat_id"
                                  label={values.order_type === "SELL" ? "Investor Demat Account *" : "Vendor Demat Account *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="bank_id"
                              // name="investorOrVendor"
                              value={values.bank_id}
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              options={vendorBankAccountList}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("bank_id", value);
                                } else {
                                  setFieldValue("bank_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.bank_id && errors.bank_id
                                  )}
                                  helperText={touched.bank_id && errors.bank_id}
                                  value={values.bank_id}
                                  name="bank_id"
                                  label={values.order_type === "SELL" ? "Investor Bank Account *" : "Vendor Bank Account *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid></>
                          : <></>
                          {(values.investorOrVendor?.type ==="COMPANY" && values.is_nse_trade) ? <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Autocomplete
                            id="investor-Or-Vendor"
                            value={values.authorizedPerson}
                            options={clientAuthPersons}
                            getOptionLabel={(option) => option.stackHolder || ""}
                            onChange={async (e, value) => {
                              if (value !== null) {
                                setFieldValue("authorizedPerson", value);
                              } else {
                                setFieldValue("authorizedPerson", "");
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean
                                 ( touched.authorizedPerson &&
                                  errors.authorizedPerson)}
                                helperText={
                                  (touched.authorizedPerson &&
                                  errors.authorizedPerson)}
                                value={values.investorOrVendor}
                                name="authorizedPerson"
                                label={"Authorized Person"}
                                fullWidth
                                variant="standard"
                              />
                            )}
                          />
                          <ErrorFocus />
                        </Grid> : null}
                      </Grid>
                    </div>
                  <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl>
                             <FormLabel component="legend">{values.order_type === "SELL" ? "Seller" : "Buyer"} Type</FormLabel>
                          <RadioGroup row aria-label="seller_type" name="seller_type"
                            value={values.seller_type}
                            id="payment_mode"
                            onBlur={handleBlur}
                            onChange={async(e)=>{
                              const value = e.target.value
                              setFieldValue("seller_type",value)
                              if(value ==='Unlistedkart'){
                                setFieldValue("disclose_client_details", false);
                                setFieldValue('facilitating_type', values.order_type ==='SELL' ? '1' : '2');
                                const primaryVendorDemat = await getSellerDematAccounts(DefaultUnlistedAccount[1].id)
                                  if (primaryVendorDemat.length > 0) {
                                    setFieldValue("order_owner_demat_id", primaryVendorDemat[0]);
                                  }else{
                                    setFieldValue("order_owner_demat_id", "");

                                  }
                                  const primaryVendorBank = await getSellerBankAccounts(DefaultUnlistedAccount[1].id);
                                  if (primaryVendorBank.length > 0) {
                                    setFieldValue("order_owner_bank_id", primaryVendorBank[0]);
                                  }else{
                                    setFieldValue("order_owner_bank_id", "");
                                  }
                                setFieldValue("order_owner", DefaultUnlistedAccount[1]);
                                setFieldValue("facilitator_bank_id", "");
                                setFieldValue("facilitator", "");
                                setFieldValue("facilitator_demat_id", "");
                              }
                            }}
                          >
                            <FormControlLabel disabled={mode === 'edit'} value={"Unlistedkart"} control={<Radio color="primary" />} label="Unlistedkart" />
                            <FormControlLabel disabled={mode === 'edit'} value={"Others"} control={<Radio color="primary" />} label="Others" />
                          </RadioGroup>
                          <FormHelperText>{touched.spa_type && errors.spa_type}</FormHelperText>
                        </FormControl>
                            </Grid>
                            {values.seller_type ==='Others' &&
                            <>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl>
                             <FormLabel component="legend">Facilitating Type</FormLabel>
                          <RadioGroup row aria-label="facilitating_type" name="facilitating_type"
                            value={values.facilitating_type}
                            id="payment_mode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {values.order_type==="SELL" &&<FormControlLabel value={"1"} control={<Radio color="primary" />} label="Sell" />}
                            {values.order_type==="BUY" && <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Buy" />}
                            <FormControlLabel value={"3"} control={<Radio color="primary" />} label="Buy and Sell" />
                          </RadioGroup>
                          <FormHelperText>{touched.spa_type && errors.spa_type}</FormHelperText>
                        </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                            <FormControl  component="fieldset" variant="standard">
                          <FormLabel component="legend" >Disclose Client Details</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.disclose_client_details}
                                name="disclose_client_details"
                                id="commissionStatus"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={(e,value)=>{                          
                                  setFieldValue('disclose_client_details',value)                          
                                }}
                                color="primary"
                              />
                              }
                              label={<Typography>{values.disclose_client_details ? "Yes" : "No"}</Typography>}
                            />
                            </FormGroup>
                        </FormControl>
                            </Grid>
                            </>}
                            </Grid>
                            </div>
                    <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                       <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="order_owner"
                              // name="investorOrVendor"
                              // disabled={mode === 'edit'}
                              value={values.order_owner}
                              // isOptionEqualToValue={(option, value) => option.code === value.code}
                              options={values.seller_type ==='Others' ? DefaultUnlistedAccount.concat(investorOrVendor) : DefaultUnlistedAccount}
                              getOptionDisabled={(option) =>
                                option === values.investorOrVendor
                              }
                              // getOptionDisabled={(option) =>{
                              //   // option.id === values.investorOrVendor
                              // }}
                              onChange={async(e, value) => {
                                if (value !== null) {
                                  setFieldValue("order_owner", value);
                                  getUlkAuthPersons(value.id)
                                  const primaryVendorDemat = await getSellerDematAccounts(value.id)
                                  if (primaryVendorDemat.length > 0) {
                                    setFieldValue("order_owner_demat_id", primaryVendorDemat[0]);
                                  }else{
                                    setFieldValue("order_owner_demat_id", "");

                                  }
                                  const primaryVendorBank = await getSellerBankAccounts(value.id);
                                  if (primaryVendorBank.length > 0) {
                                    setFieldValue("order_owner_bank_id", primaryVendorBank[0]);
                                  }else{
                                    setFieldValue("order_owner_bank_id", "");
                                  }
                                } else {
                                  setFieldValue("order_owner", "");
                                }
                              }}
                              getOptionLabel={(option) => option.lable || ""}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.order_owner && errors.order_owner
                                  )}

                                  helperText={
                                    touched.order_owner && errors.order_owner
                                  }
                                  value={values.order_owner}
                                  name="order_owner"
                                  label={values.order_type === "SELL" ? "Seller *" : "Buyer *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="order_owner_demat_id"
                              // name="investorOrVendor"
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              value={values.order_owner_demat_id}
                              options={sellerDematAccountList}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("order_owner_demat_id", value);
                                } else {
                                  setFieldValue("order_owner_demat_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.order_owner_demat_id &&
                                    errors.order_owner_demat_id
                                  )}

                                  helperText={
                                    touched.order_owner_demat_id &&
                                    errors.order_owner_demat_id
                                  }
                                  value={values.order_owner_demat_id}
                                  name="order_owner_demat_id"
                                  label={values.order_type === "SELL" ? "Seller Demat Account *" : "Buyer Demat Account *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="order_owner_bank_id"
                              // name="investorOrVendor"
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              value={values.order_owner_bank_id}
                              options={sellerBankAccountList}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("order_owner_bank_id", value);
                                } else {
                                  setFieldValue("order_owner_bank_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.order_owner_bank_id &&
                                    errors.order_owner_bank_id
                                  )}
                                  helperText={
                                    touched.order_owner_bank_id &&
                                    errors.order_owner_bank_id
                                  }
                                  value={values.order_owner_bank_id}
                                  name="order_owner_bank_id"
                                  label={values.order_type === "SELL" ? "Seller Bank Account *" : "Buyer Bank Account *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                        </> : <></>
                      </Grid>
                    </div>
                   {values.seller_type ==='Others' && <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                       <>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="facilitator"
                              disableClearable
                              // name="investorOrVendor"
                              // disabled={mode === 'edit'}
                              value={values.facilitator}
                              options={DefaultUnlistedAccount.concat(investorOrVendor)}
                              // getOptionDisabled={(option) =>{
                              //   // option.id === values.investorOrVendor
                              // }}
                              // isOptionEqualToValue={(option, value) => option.code === value.code}
                              onChange={async(e, value) => {
                                if (value !== null) {
                                  setFieldValue("facilitator", value);
                                  const primaryVendorDemat = await getSellerDematAccounts(value.id,'','facilitator')
                                  if (primaryVendorDemat.length > 0) {
                                    setFieldValue("facilitator_demat_id", primaryVendorDemat[0]);
                                  }else{
                                    setFieldValue("facilitator_demat_id", "");
                                  }
                                  const primaryVendorBank = await getSellerBankAccounts(value.id,'','facilitator');

                                  if (primaryVendorBank.length > 0) {
                                    setFieldValue("facilitator_bank_id", primaryVendorBank[0]);
                                  }else{
                                    setFieldValue("facilitator_bank_id", "");
                                  }
                                } else {
                                    setFieldValue("facilitator", "");
                                    setFieldValue("facilitator_demat_id", "");
                                    setFieldValue("facilitator_bank_id", "");
                                }
                              }}
                              getOptionLabel={(option) => option.lable || ""}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.facilitator && errors.facilitator
                                  )}

                                  helperText={
                                    touched.facilitator && errors.facilitator
                                  }
                                  value={values.facilitator}
                                  name="facilitator"
                                  label={"Facilitator *"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="facilitator_demat_id"
                              // name="investorOrVendor"
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              value={values.facilitator_demat_id}
                              options={facilitatorDematAccountList}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("facilitator_demat_id", value);
                                } else {
                                  setFieldValue("facilitator_demat_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.facilitator_demat_id &&
                                    errors.facilitator_demat_id
                                  )}

                                  helperText={
                                    touched.facilitator_demat_id &&
                                    errors.facilitator_demat_id
                                  }
                                  value={values.facilitator_demat_id}
                                  name="facilitator_demat_id"
                                  label={`Facilitator Demat Account${values.disclose_client_details ? "" : "*"}`}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="facilitator_bank_id"
                              // name="investorOrVendor"
                              // isOptionEqualToValue={(option, value) => option.id === value.id}
                              value={values.facilitator_bank_id}
                              options={facilitatorBankAccountList}
                              getOptionLabel={(option) => option.lable || ""}
                              onChange={(e, value) => {
                                if (value !== null) {
                                  setFieldValue("facilitator_bank_id", value);
                                } else {
                                  setFieldValue("facilitator_bank_id", "");
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.facilitator_bank_id &&
                                    errors.facilitator_bank_id
                                  )}
                                  helperText={
                                    touched.facilitator_bank_id &&
                                    errors.facilitator_bank_id
                                  }
                                  value={values.facilitator_bank_id}
                                  name="facilitator_bank_id"
                                  label={`Facilitator Bank Account${values.disclose_client_details ? "" : "*"}`}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                        </> : <></>
                      </Grid>
                    </div>}
                    {values.is_nse_trade  && <div style={{ padding: theme.spacing(2) }}>
                      <Grid container spacing={5} alignItems="center">
                          <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Autocomplete
                              id="facilitator"
                              disableClearable
                              // name="investorOrVendor"
                              // disabled={mode === 'edit'}
                              value={values.ulk_authorized_person}
                              options={ulkAuthPersons}
                              // getOptionDisabled={(option) =>{
                              //   // option.id === values.investorOrVendor
                              // }}
                              // isOptionEqualToValue={(option, value) => option.code === value.code}
                              onChange={async(e, value) => {
                                if (value !== null) {
                                  setFieldValue("ulk_authorized_person", value);
                                } else {
                                    setFieldValue("ulk_authorized_person", "");
                                }
                              }}
                              getOptionLabel={(option) => option.stackHolder || ""}

                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(
                                    touched.ulk_authorized_person && errors.ulk_authorized_person
                                  )}

                                  helperText={
                                    touched.ulk_authorized_person && errors.ulk_authorized_person
                                  }
                                  value={values.ulk_authorized_person}
                                  name="ulk_authorized_person"
                                  label={"Ulk Authorized Person*"}
                                  fullWidth
                                  variant="standard"
                                />
                              )}
                            />
                            <ErrorFocus />
                          </Grid>
                          <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="spa_date"
                          label="SPA Date*"
                          type="date"
                          error={Boolean(touched.spa_date && errors.spa_date)}
                          helperText={touched.spa_date && errors.spa_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="spa_date"
                          value={values.spa_date}
                          variant="standard"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="stage1_document_date"
                          label="Stage1 Document Date*"
                          type="date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="stage1_document_date"
                          value={values.stage1_document_date}
                          variant="standard"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>
                      </Grid>
                      
                    </div>}
                  </>
                ) : (
                  <div></div>
                )}
              </NormalCard>
              <NormalCard
                title="Scrip Details"
                btn={mode === null ?
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    disabled={values.transaction_type === ""}
                    size="medium"
                    onClick={() => handleShow(values.transaction_type,"Add")}
                    variant="contained"
                  >
                    Add New Scrip
                  </Button>
                  : <></>
                }
              >
               {isHiddenScripExist.length>0 ? <Grid container>
                  <Grid item xs={12} p={2} >
                  <Typography sx={{color:'red'}}>  <span style={{color:'black', fontWeight:'bold'}}>Warning : </span> This scrip is not available for trading and requires approval from the operations team, but you can still create an order for now.</Typography>
                  </Grid>
                </Grid> : null}
                <div style={{ padding: theme.spacing(2) }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TableContainer  component={Paper}>
                        <Table
                          className={classes.table}
                          style={{ color: "#314b8f" }}
                        >
                          {(values.transaction_type === "INTERMEDIATE") ? (
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  Sl No
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  SecurityName
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Quantity
                                </TableCell>
                                {values.order_type === "SELL" && <TableCell className={classes.tableCell}>
                                  Buy Price
                                </TableCell>}
                                <TableCell className={classes.tableCell}>
                                  {values.order_type === "SELL" ? "Sold/Client Price" : "Buy/Client Price"}
                                </TableCell>
                                {values.is_nse_trade  &&
                                <>
                                <TableCell className={classes.tableCell}>
                                  Documentation Charges
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Stamp Duty Chrages
                                </TableCell>
                                </>
                                 }
                                <TableCell className={classes.tableCell}>
                                  RM Price
                                </TableCell>
                                {values.order_type === "SELL" &&  <TableCell className={classes.tableCell}>
                                  Procured By
                                </TableCell>}
                                <TableCell className={classes.tableCell}>
                                  Total Amount
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                ></TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                >Action</TableCell>
                              </TableRow>
                            </TableHead>
                          ) : (
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  Sl No
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Scrip Name
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Quantity
                                </TableCell>
                                {values.order_type === "SELL" && <TableCell className={classes.tableCell}>
                                  Buy Price
                                </TableCell>}
                                <TableCell className={classes.tableCell}>
                                 {values.order_type === "SELL"  ?"Sold Price" : "Buy Price"}
                                </TableCell>
                                {values.is_nse_trade  &&
                                <>
                                <TableCell className={classes.tableCell}>
                                  Documentation Charges
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  Stamp Duty Chrages
                                </TableCell>
                                </>
                                 }
                                {values.order_type === "SELL" && <TableCell className={classes.tableCell}>
                                  ProcuredBy
                                </TableCell>}
                                <TableCell className={classes.tableCell}>
                                  Total Amount
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                ></TableCell>
                                {mode !== "edit" && <TableCell
                                  className={classes.tableCell}
                                >Action</TableCell>}
                              </TableRow>
                            </TableHead>
                          )}
                          <TableBody>
                            {(values.transaction_type === "INTERMEDIATE" )
                              ? securityTableData.sort((a, b) => a.security_name.localeCompare(b.security_name)).map((item, i) => {
                                return (
                                  <TableRow key={i}>
                                    <TableCell className={classes.tableCell}>
                                      {i + 1}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.security_name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.quantity}
                                    </TableCell>
                                   { values.order_type === "SELL" && <TableCell className={classes.tableCell}>
                                      {item.buyPrice}
                                    </TableCell>}
                                    <TableCell className={classes.tableCell}>
                                      {Number(item.price).toFixed(3)}
                                    </TableCell>
                                    { values.is_nse_trade && <>
                                      <TableCell className={classes.tableCell}>
                                      {item.documentationCharges}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.stampDutyFee}
                                    </TableCell>
                                    </>}
                                    <TableCell className={classes.tableCell}>
                                      {mode === null ? Number(item.commission).toFixed(3) : Number(item.commision).toFixed(3)}
                                    </TableCell>
                                   {values.order_type === "SELL" &&  <TableCell >
                                    {item.procuredBy?.length>0 ?  <Tooltip title={renderTooltipTitle(item)}> 

                                      <InfoIcon/>
                                     </Tooltip> : <Typography style={{marginLeft:'8px'}}>-</Typography>}
                                    </TableCell>}
                                    <TableCell className={classes.tableCell}>
                                      {Number(item.consolidated_price).toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                  className={classes.tableCell}
                                ></TableCell>
                                    <TableCell className={classes.tableCell}>
                                       <IconButton
                                aria-label="delete"
                                style={{ color: "#314b8f",padding:0 }}
                                // disabled={serviceOrderBasicData.status?.label !=='Active' }
                                onClick={() => {
                                  handleShow(values.transaction_type,"Edit",item);
                                }}
                              >
                                <EditIcon/>
                              </IconButton>
                              {mode !== "edit" &&   <IconButton
                                          aria-label="delete"
                                          style={{ color: "#314b8f" }}
                                          onClick={() => {
                                            handleDelete(item);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>}
                                      </TableCell>
                                  </TableRow>
                                );
                              })
                              : securityTableData.sort((a, b) => a.security_name.localeCompare(b.security_name)).map((item, i) => {
                                return (
                                  <TableRow key={i}>
                                    <TableCell className={classes.tableCell}>
                                      {i + 1}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.security_name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.quantity}
                                    </TableCell>
                                    { values.order_type === "SELL" && <TableCell className={classes.tableCell}>
                                      {item.buyPrice}
                                    </TableCell>}
                                    <TableCell className={classes.tableCell}>
                                      {Number(item.price).toFixed(3)}
                                    </TableCell>
                                    { values.is_nse_trade && <>
                                      <TableCell className={classes.tableCell}>
                                      {item.documentationCharges}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {item.stampDutyFee}
                                    </TableCell>
                                    </>}
                                   {values.order_type === "SELL" &&  <TableCell className={classes.tableCell}>
                                    {item.procuredBy?.length>0 ?  <Tooltip title={renderTooltipTitle(item) }> 
                                      <InfoIcon/>
                                     </Tooltip> : values.order_type === "BUY" ? null :<Typography style={{marginLeft:'8px'}}>-</Typography>}
                                    </TableCell>}

                                    <TableCell className={classes.tableCell}>
                                      {Number(item.consolidated_price).toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                  className={classes.tableCell}
                                ></TableCell>
                                      <TableCell className={classes.tableCell}>
                                       <IconButton
                                        // disabled={serviceOrderBasicData.status?.label !=='Active' }

                                aria-label="delete"
                                style={{ color: "#314b8f",padding:0 }}
                                onClick={() => {
                                  handleShow(values.transaction_type,"Edit",item);
                                }}
                              >
                                <EditIcon/>
                              </IconButton>
                              {mode !== "edit"&&   <IconButton
                                          aria-label="delete"
                                          style={{ color: "#314b8f" }}
                                          onClick={() => {
                                            handleDelete(item);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>}
                                      </TableCell>
                                      
                                  </TableRow>
                                )
                              })}
                            {values.transaction_type === "INTERMEDIATE"   &&
                              <>

                                {values.order_type === "BUY" && <TableRow>
                                  {/* <TableCell rowSpan={3} /> */}
                                  <TableCell colSpan={5} />

                                  <TableCell
                                    style={{
                                      color: "#314b8f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Total Client  Price
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "#314b8f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {totalConsolidatedPrice}
                                  </TableCell>
                                  <TableCell colSpan={5} />
                                  

                                </TableRow>}
                                <TableRow>
                                  <TableCell colSpan={5} />

                                  <TableCell
                                    style={{
                                      color: "#314b8f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Total RM Commission
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "#314b8f",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    ₹{(parseFloat(totalCommissionPrice(values.order_type)).toFixed(3))}
                                  </TableCell>
                                  <TableCell colSpan={5} />

                                </TableRow>
                              </>
                            }
                            {values.seller_type ==='Others' &&
                             <>
                               <TableRow>
                               <TableCell colSpan={5} />

                               <TableCell
                                 style={{
                                   color: "#314b8f",
                                   fontSize: 14,
                                   fontWeight: 500,
                                 }}
                               >
                                 Total Facilitator Commission
                               </TableCell>
                               <TableCell
                                 style={{
                                   color: "#314b8f",
                                   fontSize: 14,
                                   fontWeight: 500,
                                 }}
                               >
                                 ₹{(parseFloat(totalFacilitatorCommissionPrice(values.order_type)).toFixed(3))}
                               </TableCell>
                               <TableCell colSpan={5} />

                             </TableRow>
                             </>
                            }

                            <TableRow>
                              <TableCell colSpan={5} />

                              <TableCell
                                style={{
                                  color: "#314b8f",
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                {values.order_type === "SELL" ? "Total Sold Price" : "Total Buy Price"}
                              </TableCell>
                              <TableCell
                                style={{
                                  color: "#314b8f",
                                  fontSize: 16,
                                  fontWeight: 600,
                                }}
                              >
                                {values.order_type === "BUY" && values.transaction_type === "INTERMEDIATE" ? buyTotalPrice(values.order_type) : totalConsolidatedPrice}
                              </TableCell>

                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              {/* <Grid item xs={12} sm={6} md={12} lg={12}> */}
              {
              (showTcsOrTds && values.seller_type  ==="Unlistedkart") ? (
                  <>
                    <NormalCard title="TCS or TDS details">
                      <div style={{ padding: theme.spacing(2) }}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                style={{ color: "#314b8f" }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={classes.tableCell}>
                                      Total transacted amount
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      Current transaction amount
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      Applicable for TCS/TDS
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      TDS
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      TCS
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      Action
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell className={classes.tableCell}>
                                      {
                                        totalPreviousOrderSumFormated
                                      }
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {
                                        totalConsolidatedPrice
                                      }
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {
                                        tcsTdsApplicableSumFormated
                                      }
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {tdsValueFormated}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {tcsValueFormatted}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      <IconButton
                                        aria-label="edit"
                                        style={{ color: "#314b8f" }}
                                        onClick={() => {
                                          setTitleModal('Collect TSC or TDS')
                                          setOpenModalForTcsAndTds(true)
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell colSpan={3} />

                                    <TableCell
                                      style={{
                                        color: "#314b8f",
                                        fontSize: 16,
                                        fontWeight: 600,
                                      }}>
                                      Total order value
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        color: "#314b8f",
                                        fontSize: 16,
                                        fontWeight: 600,
                                      }}>
                                      {
                                        totalOrderValueFormated
                                      }
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                        {((tcsValue === 0 && tdsValue === 0) && values.seller_type  ==="Unlistedkart") && <FormHelperText style={{ color: "#F44336" }}>
                          TDS or TCS is required
                        </FormHelperText>}
                      </div>
                    </NormalCard>
                  </>


                ) : (<></>)

              }
              {/* </Grid> */}
              {!values.is_purchased_online && 
                <NormalCard title="Payment Info">
                  <div style={{ padding: theme.spacing(2) }}>
                    <Grid container spacing={5} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="payment_date"
                          label="Payment Date*"
                          type="date"
                          error={Boolean(touched.payment_date && errors.payment_date)}
                          helperText={touched.payment_date && errors.payment_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="payment_date"
                          value={values.payment_date}
                          variant="outlined"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="transaction_date"
                          label="Transaction Date*"
                          type="date"
                          error={Boolean(touched.transaction_date && errors.transaction_date)}
                          helperText={touched.transaction_date && errors.transaction_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="transaction_date"
                          value={values.transaction_date}
                          variant="outlined"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <FormControl component="fieldset" error={Boolean(touched.payment_mode && errors.payment_mode)}>
                          <FormLabel component="legend">Payment Mode</FormLabel>
                          <RadioGroup row aria-label="payment_mode" name="payment_mode"
                            value={values.payment_mode}
                            id="payment_mode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          // error={Boolean(touched.payment_mode && errors.payment_mode)}
                          // helperText={touched.payment_mode && errors.payment_mode}
                          >
                            <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Prepaid" />
                            <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Postpaid" />
                          </RadioGroup>
                          <FormHelperText>{touched.payment_mode && errors.payment_mode}</FormHelperText>
                        </FormControl>
                        <ErrorFocus />
                      </Grid>
                    </Grid>
                    {(values.transaction_type ==="INTERMEDIATE" || values.is_nse_trade) &&
                   <>
                   <Grid container spacing={5} alignItems="center">      
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                      <FormControl  component="fieldset" variant="standard">
                          <FormLabel component="legend">Commission status</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.commissionStatus}
                                name="commissionStatus"
                                id="commissionStatus"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="primary"
                              />
                              }
                              label={<Typography>{values.is_nse_trade ? "Received" :"Paid"}</Typography>}
                            />
                            </FormGroup>
                        </FormControl>
                        </Grid>
                      {values.commissionStatus &&  <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="commission_paid_date"
                          label= {values.is_nse_trade? "Commission Received Date": "Commission Paid Date"}
                          type="date"
                          error={Boolean(touched.commission_paid_date && errors.commission_paid_date)}
                          helperText={touched.commission_paid_date && errors.commission_paid_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="commission_paid_date"
                          value={values.commission_paid_date}
                          variant="outlined"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>}
                      </Grid>
                      {
                        values.transaction_type ==="INTERMEDIATE"  &&(
                          <>
                              <Typography sx={{mt:3,mb:3, fontWeight:600}}>Channel Partner Commission Report</Typography>
                  <Grid container>
                  <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <FormControl component="fieldset" error={Boolean(touched.commission_split_type && errors.commission_split_type)}>
                          <FormLabel component="legend">Commission Type</FormLabel>
                          <RadioGroup row aria-label="commission_split_type" name="commission_split_type"
                            value={values.commission_split_type}
                            id="commission_split_type"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          // error={Boolean(touched.payment_mode && errors.payment_mode)}
                          // helperText={touched.payment_mode && errors.payment_mode}
                          >
                            <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Percentage" />
                            <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Amount" />
                          </RadioGroup>
                          <FormHelperText>{touched.payment_mode && errors.payment_mode}</FormHelperText>
                        </FormControl>
                        <ErrorFocus />
                      </Grid>
                  </Grid>
                  {values.channel_partner_report?.map((_, index) => {
                     const touchedCommissionValue = getIn(touched, `channel_partner_report.${index}.commission_value`);
                     const errorCommissionValue = getIn(errors, `channel_partner_report.${index}.commission_value`);
                     const touchedCommissionPercentage = getIn(touched, `channel_partner_report.${index}.commission_percentage`);
                     const errorCommissionPercentage = getIn(errors, `channel_partner_report.${index}.commission_percentage`);
                     const touchedCommissionPaidDate = getIn(touched, `channel_partner_report.${index}.commission_paid_date`);
                     const errorCommissionPaidDate = getIn(errors, `channel_partner_report.${index}.commission_paid_date`);
                    return(
                      (
                        <div key={index}>
                          {/** both these conventions do the same */}
                          <Grid container spacing={5} mb={1} alignItems="center" key={index}>
                          <Grid
                          item
                          xs={3}
                          className={classes.gridPadding}
                        >
                          <Typography>{values.channel_partner_report[index].name}</Typography>
                           <ErrorFocus />
                          </Grid>
                          <Grid
                           item
                           xs={2}
                           className={classes.gridPadding}
                          >
                          <TextField
                           label="Value *"
                           id="investor_key_value"
                           autoComplete="off"
                           fullWidth
                           disabled={values.commission_split_type ==="1"}
                           variant="standard"
                           type="number"
                           name={`channel_partner_report.${index}.commission_value`}
                           value={values.channel_partner_report[index].commission_value}
                            onBlur={handleBlur}
                            onChange={(e)=>{
                              const Commission =  Math.abs(parseFloat(totalCommissionPrice(values.order_type)).toFixed(3))
                              setFieldValue(`channel_partner_report.${index}.commission_value`,Number(e.target.value) )
                              setFieldValue(`channel_partner_report.${index}.commission_percentage`,(e.target.value ===0 || Commission===0)  ? 0 :Number(Math.abs(parseFloat((e.target.value*100)/Commission).toFixed(2))))
                            }}
                            error={Boolean( touchedCommissionValue && errorCommissionValue)}
                            helperText={ touchedCommissionValue && errorCommissionValue}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={2}
                          className={classes.gridPadding}
                        >
                          <TextField
                           label="Percentage*"
                           variant="standard"
                           type="number"
                           id="commission_percentage"
                           name={`channel_partner_report.${index}.commission_percentage`}
                           value={values.channel_partner_report[index].commission_percentage}
                           autoComplete="off"
                           fullWidth
                           onChange={(e)=>{
                            const Commission =  (parseFloat(totalCommissionPrice(values.order_type)).toFixed(3))
                            setFieldValue(`channel_partner_report.${index}.commission_value`,Number((e.target.value*Commission)/100).toFixed(3))
                            setFieldValue(`channel_partner_report.${index}.commission_percentage`,Number(e.target.value))
                           }}
                           disabled={values.commission_split_type ==="2"}
                           onBlur={handleBlur}
                           error={Boolean(touchedCommissionPercentage && errorCommissionPercentage)}
                           helperText={touchedCommissionPercentage && errorCommissionPercentage}
                           />
                           <ErrorFocus />
                          </Grid>
                          <Grid
                          item
                          xs={1}
                          className={classes.gridPadding}
                        >
                           <FormControlLabel
                              control={
                                <Checkbox
                                disabled={role==='SALES'}
                                checked={values.channel_partner_report[index].commission_paid_status}
                                name={`channel_partner_report.${index}.commission_paid_status`}
                                id="commissionStatus"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={(e,value)=>{  
                                  setFieldValue(`channel_partner_report.${index}.commission_paid_status`, value)
                                  if(!value){
                                    setFieldValue(`channel_partner_report.${index}.commission_paid_date`,'')
                                  }
                                }}
                                color="primary"
                              />
                              }
                              label={<Typography>Paid</Typography>}
                            />
                          </Grid>
                         { values.channel_partner_report[index].commission_paid_status && <Grid
                          item
                          xs={2}
                          className={classes.gridPadding}
                        >
                          <TextField
                           InputLabelProps={{
                            shrink: true,
                            }}
                            // InputProps={{ disableUnderline: true, }}
                            style={{marginTop:"8px"}}
                           label="Commission Paid Date*"
                           id="investorcolor"
                           variant="outlined"
                           type="date"
                           disabled={!values.channel_partner_report[index].commission_paid_status || role==='SALES'}
                           name={`channel_partner_report.${index}.commission_paid_date`}
                           value={values.channel_partner_report[index].commission_paid_date}
                           autoComplete="off"
                           fullWidth
                           onChange={handleChange}
                           onBlur={handleBlur}
                           error={Boolean(touchedCommissionPaidDate && errorCommissionPaidDate)}
                           helperText={touchedCommissionPaidDate && errorCommissionPaidDate}
                           />
                           <ErrorFocus />
                          </Grid>}
                        
                          </Grid>
                            </div>
                          )
                    )
                  })}
                     
                     <Typography><b>Note</b>:<Typography component={'span'} sx={{color:(Number(parseFloat(values.channel_partner_report.reduce((accumulator, currentObject) =>  Number(currentObject['commission_value']) +accumulator,0)).toFixed(2))) !== Number(parseFloat(totalCommissionPrice(values.order_type))) && values.channel_partner_report.length !==0
                          ? 'red' :''}}> Total percentage should be 100% or value should be equal to total commission amount</Typography> </Typography>
                          </>
                        )
                      }
              </>
                      }
                  </div>
                </NormalCard>}

            <NormalCard title="Others">
                <div style={{ padding: theme.spacing(2) }}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Autocomplete
                        id="referral"
                        // name="selectReferral"
                        value={values.referral}
                        options={channelPartner}
                        // isOptionEqualToValue={(option, value) => option.code === value.code}
                        getOptionLabel={(option) => option.lable || ""}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setFieldValue("referral", value);
                            setFieldValue("referral_amount", "");
                          } else {
                            setFieldValue("referral", "");
                            setFieldValue("referral_amount", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}

                            name="referral"
                            label="Select Referral"
                            fullWidth
                            variant="standard"
                          />
                        )}
                      />

                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <TextField
                        id="referral_amount"
                        value={values.referral_amount === null ||
                          values.referral_amount === undefined
                          ? ""
                          : values.referral_amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="referral_amount"
                        type="number"
                        label="Enter Referral Fee"
                        fullWidth
                        variant="standard"
                        error={Boolean(touched.referral_amount && errors.referral_amount)}
                        helperText={touched.referral_amount && errors.referral_amount}
                        autoComplete="off"
                      />
                    </Grid>
                    <ErrorFocus />
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                      <FormControl  component="fieldset" variant="standard">
                          <FormLabel component="legend">Referral Paid status</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.referral_paid_status}
                                name="referral_paid_status"
                                id="referral_paid_status"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="primary"
                              />
                              }
                              label={<Typography>{"Paid"}</Typography>}
                            />
                            </FormGroup>
                        </FormControl>
                        </Grid>
                      {values.referral_paid_status &&  <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        className={classes.gridPadding}
                      >
                        <TextField
                          fullWidth
                          id="referral_paid_date"
                          label= {"Referral Paid Date"}
                          type="date"
                          error={Boolean(touched.referral_paid_date && errors.referral_paid_date)}
                          helperText={touched.referral_paid_date && errors.referral_paid_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="referral_paid_date"
                          value={values.referral_paid_date}
                          variant="outlined"
                          InputLabelProps={{
                            readOnly: true,
                            shrink: true
                          }}
                        />
                        <ErrorFocus />
                      </Grid>}
                      <Grid item xs={12} sm={6} md={12} lg={12}> 
                      <Grid item xs={12} sm={6} md={3} lg={3}> 
                    <Typography className={classes.label}>Custom branding image</Typography>
                    <OutlinedInput
                      accept="image/*"
                      // className={classes.input}
                      id="icon-button-file"
                      name="podCopy"
                      fullWidth={true}  
                      error={touched.dematImage && Boolean(errors.dematImage)}
                      // helperText={touched.dematImage ? errors.dematImage : ""}
                      onChange={(e) => {
                        setFieldValue("dematImage", e.currentTarget.files[0]);
                      }}
                      type="file"
                    />

                    <FormHelperText style={{ color: "#F44336",paddingLeft:16 }}>
                      {touched.dematImage ? errors.dematImage : ""}
                    </FormHelperText>
                      </Grid>
                      <FormControl  style={{marginTop:'1rem'}} component="fieldset" color="primary">
                        <FormGroup
                          value={values.is_purchased_online}
                        >
                          <FormControlLabel
                            value={values.is_purchased_online}
                            checked={values.is_purchased_online}
                            color="primary"
                            control={
                              <Checkbox
                                // error={Boolean(
                                //   errors.is_purchased_online && touched.is_purchased_online
                                // )}
                                // helperText={errors.is_purchased_online && touched.is_purchased_online}
                                color="primary"
                                onChange={(e, value) => {
                                  if (value === true) {
                                    setFieldValue("is_purchased_online", value);
                                    setFieldValue("payment_date", '')
                                    setFieldValue("transaction_date", '')
                                    setFieldValue("payment_mode", "")
                                  } else {
                                    setFieldValue("is_purchased_online", value);
                                  }
                                }}
                                name="is_purchased_online"
                              />
                            }
                            label={"Order for Online Request"}
                          />
                        </FormGroup>
                      </FormControl>
                      <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                        {errors.is_purchased_online &&
                          touched.is_purchased_online &&
                          errors.is_purchased_online}
                      </FormHelperText>
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                </div>
                <div style={{ padding: theme.spacing(2), display: "flex" }}>
                  <Grid container spacing={5} alignItems="center">
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <Typography>Remarks</Typography>
                      <Field
                        name="remarks"
                        render={({ field, form }) => {
                          return (
                            <>
                            <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                config={{
                                  toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', 'insertTable',
                                    '|', 'undo', 'redo']
                                }}
                                onChange={(e, editor) => {
                                  form.setFieldValue("remarks", editor.getData());
                                }}
                              />
                            </>
                          )
                        }}
                      />
                      <FormHelperText style={{ color: errors.remarks && "#F44336" }}>
                        {touched.remarks && errors.remarks}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} >
                      <Button
                        color="primary"
                        className={classes.popDownBtn}
                        size="medium"
                        // eslint-disable-next-line no-mixed-operators
                        disabled={(securityTableData.length === 0 || (((tcsValue === 0 && tdsValue === 0) && values.seller_type  ==="Unlistedkart") &&  showTcsOrTds)  || (values.transaction_type === "INTERMEDIATE" &&  (Number(parseFloat(values.channel_partner_report.reduce((accumulator, currentObject) =>  Number(currentObject['commission_value']) +accumulator,0)).toFixed(2))) !== Number(parseFloat(totalCommissionPrice(values.order_type)))) && values.channel_partner_report.length  !==0 )}
                        type="submit"
                        variant="contained"
                        style={{ float: "right"}}
                      >
                        {loading && <img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} />}
                        {mode === "edit" ? "Update Order" : "Create Order"}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </NormalCard>
              <ModalCentered
                  title={'Order Details'}
                  open={invoiceDialog}
                  fullScreen={true}
                  toggle={handleShowClose}
                  children={
                    <InvoiceForm
                    onConfirm={()=>{
                      setLoading(true);
                      createServiceOrder(values);
                    }}
                    loading={loading}
                    onClose={handleShowClose}
                    data={{...values,
                      "securityTableData":securityTableData,
                      "totalConsolidatedPrice":totalConsolidatedPrice,
                      "totalCommissionPrice":totalCommissionPrice,
                      "totalFacilitatedCommissionPrice":totalFacilitatorCommissionPrice,
                      "buyTotalPrice":buyTotalPrice,
                      "tdstcsValue":tdsTcsInitialValue,
                      "totalAmount":totalOrderValueFormated
                    }}
                    />
                  }
                  
                />
                   <ModalCentered
          title={titleModal}
          open={openModal}
          toggle={handleShowClose}
          children={
            <ServiceOrderCreateForm
             item={editItem}
             getSecurityData={getSecurityData}
              mode={mode}
              formSubmit={(value)=>{
                submitHandle(value)
                setFormikValues(values)
              }}
              facilitator={values.seller_type}
              nse={values.is_nse_trade}
              procuredBy={salesAndOperation}
              transactionType={transactionType}
              serviceOrderType={serviceOrderType}
              securityData={securityTableData}
            />
          }
                />

</div>
                    )}
                    </FieldArray>
            </form>
          )}
        </Formik>
      </Page>
      <>

        <SessionExpiredModal
          open={openSessionExpiredModal}
        />

        <ModalCentered
           title={'Update TDS TCS for Future Orders'}
           open={openTax && (showTcsOrTds || totalOrderValue) && isNSE}
           maxWidth={'md'}
          toggle={()=>setOpenTax(true)}
          children={
            <TaxModel
              formSubmit={handleFutureOrdersTax}
              data={futureOrders}
            />
          }
        />


        <ModalCentered
          title={titleModal}
          open={openModalForTcsAndTds}
          toggle={handleShowTdsClose}
          children={
            <TcsOrTdsModal
              data={tdsTcsInitialValue}
              taxType={taxType}
              applicableSum={tcsTdsApplicableSum}
              formSubmit={submitTCSTDSHandle} />
          }
        />
        <SnackBar
          open={snackBarOpen}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />
      </>
    </Root>
  );
}


const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps, null)(CreateServiceOrder);
